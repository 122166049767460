import { Outlet } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../Components/Login.css"
export default function Acesso() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Container className="Login_form">
              <Card  >
                <Outlet />
              </Card>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  )
}