import React, { useState } from "react";
import { Card, Table } from "react-bootstrap";
import { docto, formatarValorQuilograma } from "../../Components/formatos";
import ItensCe from "../../07_Operacao/7.0_Componentes/7.0.1_ItemCe";

export default function ListaCeReduzida({ Ces }) {
  let total = 0;
  let vol = 0;
  const [Selecionado, setSel] = useState({});
  const Selecionar2 = (ce, index) => {
    setSel({})
    setTimeout(() => {
      setSel({ ...ce, seq: index })
    }, 100);
  };
  return (<>
    <Card className="mb-3 mt-3">
      <Card.Header>Conhecimento Eletronico</Card.Header>
      <Card.Body>
        <Table bordered responsive striped hover size="sm">
          <thead>
            <tr>
              <th>CeMercante</th>
              <th>Beneficiario</th>
              <th>Documento</th>
              <th>Carga/Descarga</th>
              <th>Vol/Peso</th>
            </tr>
          </thead>
          <tbody>
            {!!Ces && Ces.map((ce, index) => {
              total += ce.peso;
              vol += ce.items.length;
              return (
                <tr key={ce.CeNumero} title={ce.descricao} onDoubleClick={() => Selecionar2(ce, index)}>
                  <td>{ce.CeNumero}</td>
                  <td>{docto(ce.ConsgDoc)} {ce.ConsgNome}</td>
                  <td>{ce.tipoConhec}: {ce.BLOrig}</td>
                  <td>{ce.OrigemCod} - {ce.DestinoCod}</td>
                  <td>{ce.items.length} - {formatarValorQuilograma(ce.peso)}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={4}>Totais</th>
              <th>{vol} - {formatarValorQuilograma(total)}</th>
            </tr>
          </tfoot>
          <ItensCe CeMercante={Selecionado} />
        </Table>
      </Card.Body>
    </Card>
  </>
  );
}