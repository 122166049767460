import React from 'react';
import {
  Table,
  Row,
  Col,
  Card,
} from 'react-bootstrap';
import AdParalizacao from './7.0_Componentes/7.0.2_form_paralizacao';

export default function Statement() {
  return (
    <>
      <Card className='mt-3'>
        <Card.Header>
          <Row>
            <Col md={11}>
              Paralizações
            </Col>
            <Col>
              <AdParalizacao />
            </Col>
          </Row>
        </Card.Header>
        <Row className='justify-content-md-center m-2'>
          <Col md={6}>
            <h4>Statement of Facts</h4>
            <Table responsive striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Observação</th>
                  <th>Horarios</th>
                  <th>Dia</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Observação</td>
                  <td>Dia</td>
                  <td>Horarios</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col>
            <h4>Calculos</h4>
            <Card>
              <Card.Body>
                Tempo da operação: __dias __horas __minutos<br />
                Tempo de paralização: __dias __horas __minutos<br />
                Tempo operando: __dias __horas __minutos<br />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className='justify-content-md-center mt-4 m-2'>
          <Col md={8}>
            <h4>Operation Report</h4>
            <Table responsive striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Dia</th>
                  <th>Horarios</th>
                  <th>Observação</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Dia</td>
                  <td>Horarios</td>
                  <td>Observação</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card>
    </>
  );
}
