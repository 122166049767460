/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Button, Form, Card, InputGroup, Row, Col, ButtonGroup,
} from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { CheckCnpj, docto, datetimeLocal, formatarValorQuilograma } from '../Components/formatos';
import config from '../Components/config/config';
import BuscarVeiculo from '../04_Veiculo/Veiculo_form_buscar';
import BuscaEmpresa from '../03_Empresa/3.3_EmpresaPorNome';

import { child, get, getDatabase, ref, set } from 'firebase/database';
import ListaManifesto from './5.0_Componentes/5.0.1_tabela_manifesto';

export default function CadEscala() {
  const hoje = datetimeLocal(new Date());
  const Authorization = config.Key;
  const { Escala } = useParams();
  const [Loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [Ope, setOperacoes] = useState([{ id: '1', titulo: 'Descarga' }, { id: '2', titulo: 'Carga' },]);
  const navigate = useNavigate();
  const [Values, setValues] = useState({
    atr: Date.now(),
    prevista: datetimeLocal(new Date()),
    navio: '',
    imo: '',
    Escala: '',
    viagem: '',
    peso: '',
    manifestos: []
  });
  const [tab, setTab] = useState(true);
  const [botao, setBotao] = useState({});
  async function BuscarResv() {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `cop/Escala/${Escala}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const value = snapshot.val();
          setValues(value);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
    /* const reqs = await fetch(`${config.urlRootNode}Programacao/Buscar_prog`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
      body: JSON.stringify({ resv }),
    });
    if (reqs.status === 200) {
      const ress = await reqs.json();
      setValues((prevState) => ({ ...prevState, ...ress, vali_id: 1 }
      ));
    } */
  }
  async function CadastraProgramacao() {
    const db = getDatabase();
    setLoading(true);
    set(ref(db, `cop/Escala/${Values.atr}`), Values);
    navigate(`/Listaope/${Values.atr}`);
    /* const reqs = await fetch(`${config.urlRootNode}Programacao/Nova_Programacao`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
      body: JSON.stringify(Dados),
    });
    setLoading(false);
    if (reqs.status === 200) {
      // const ress = await reqs.json();
      setTimeout(() => {
        setLoading(true);
        navigate('/Agendamento');
      }, 3000);
    } */
  }
  useEffect(() => {
    if (Escala) {
      BuscarResv(Escala);
      setBotao({ titulo: 'Salvar', tipo: 'warning' });
      setTab(false);
    } else {
      setBotao({ titulo: 'Cadastrar', tipo: 'primary' });
      setTab(true);
    }
  }, [Escala]);
  async function EditaProgramacao(Dados) {
    const reqs = await fetch(`${config.urlRootNode}Programacao/Editar_Programacao`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
      body: JSON.stringify(Dados),
    });
    if (reqs.status === 200) {
      // const ress = await reqs.json();
      navigate('/Agendamento');
    }
  }
  const EventoCadastro = (event) => {
    event.preventDefault();
    if (tab) {
      CadastraProgramacao(Values); // backend
    } else {
      EditaProgramacao(Values); // backend
    }
  };
  /*   async function buscarOperacao() {
      const reqs = await fetch(`${config.urlRootNode}Programacao/Lista_operacao`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization,
        },
      });
      setOperacoes(reqs.status === 200 ? await reqs.json() : []);
    }
    useEffect(() => {
      buscarOperacao();
    }, []); */
  async function BuscaCnpj(valor) {
    const CnpjBuscado = valor.replace(/\D/g, '');
    const reqs = await fetch(`${config.urlRootNode}Emp/Buscar_empresa`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
      body: JSON.stringify({ CnpjBuscado }),
    });
    const dados = await reqs.json();
    return (dados);
  }
  useEffect(() => {
    if (CheckCnpj(Values.beneficiarioDoc)) {
      BuscaCnpj(Values.beneficiarioDoc).then((val) => {
        setValues((prevState) => ({
          ...prevState, beneficiarioId: val.id, beneficiario_nome: val.NomeFantasia,
        }));
      });
    } else {
      setValues((prevState) => ({
        ...prevState, beneficiarioId: '', beneficiario_nome: '',
      }));
    }
  }, [Values.beneficiarioDoc]);
  useEffect(() => {
    if (CheckCnpj(Values.AgenciaDoc)) {
      BuscaCnpj(Values.AgenciaDoc).then((val) => {
        setValues((prevState) => ({
          ...prevState, AgenciaId: val.id, Agencia_nome: val.NomeFantasia,
        }));
      });
    } else {
      setValues((prevState) => ({
        ...prevState, AgenciaId: '', Agencia_nome: '',
      }));
    }
  }, [Values.AgenciaDoc]);
  async function BuscaPlaca(veic) {
    const reqs = await fetch(`${config.urlRootNode}Veiculos/Veiculo`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
      body: JSON.stringify({
        placa: veic,
      }),
    });
    if (reqs.status === 200) {
      const ress = await reqs.json();
      setValues((prevState) => ({ ...prevState, cavaloId: ress.id }));
    } else {
      setValues((prevState) => ({ ...prevState, cavaloId: 'Placa não cadastrada' }));
    }
  }
  useEffect(() => {
    if (Values.imo.length >= 7) {
      // BuscaPlaca(Values.cavalo);
    } else {
      setValues((prevState) => ({ ...prevState, NavioId: '' }));
    }
  }, [Values.cavalo]);
  const Agencia = (childdata) => {
    setValues((prevState) => ({
      ...prevState,
      AgenciaId: childdata.id,
      Agencia_nome: childdata.NomeFantasia,
      AgenciaDoc: childdata.Cnpj,
    }));
  };
  const veiculo = (child) => {
    setValues((prevState) => ({ ...prevState, NavioId: child.id, Navio: child.nome }));
  };
  return (
    <Card>
      <Card.Header as="h3">Cadastro de Atracação</Card.Header>
      <Card.Text hidden={Loading} style={{ alignSelf: 'center' }}>
      </Card.Text>
      <Card.Body hidden={!Loading}>
        <Form id="formulario" onSubmit={EventoCadastro} className="mb-2">
          <Row>
            <Form.Group as={Col} md={3} className="mb-3">
              <Form.Label>Navio</Form.Label>
              <InputGroup size="sm">
                <Form.Control
                  required
                  type="text"
                  readOnly
                  Value={Values.navio}
                  placeholder="Navio"
                  name="Navio"
                />
                <BuscarVeiculo placa={veiculo} />
              </InputGroup>
              <Form.Text className="text-muted">
              </Form.Text>
            </Form.Group>
            <Form.Group as={Col} md={3}>
              <Form.Label>Viagem</Form.Label>
              <Form.Control
                size='sm'
                required
                type='text'
                placeholder='Viagem'
                name='viagem'
                Value={Values.viagem}
                onChange={(e) => setValues((a) => ({ ...a, viagem: e.target.value }))}
              />
            </Form.Group>
            <Form.Group as={Col} md={3}>
              <Form.Label>Escala</Form.Label>
              <Form.Control
                size='sm'
                type='text'
                placeholder='Escala'
                name='Escala'
                value={Values.Escala}
                onChange={(e) => setValues((a) => ({ ...a, Escala: e.target.value }))}
              />
            </Form.Group>
            <Form.Group as={Col} className="mb-3" mb={3} size="sm">
              <Form.Label>Agencia</Form.Label>
              <InputGroup size="sm">
                <Form.Control
                  required
                  autoComplete="false"
                  type="text"
                  value={docto(Values.AgenciaDoc)}
                  maxLength="18"
                  placeholder="Cnpj da Agencia"
                  onChange={(a) => {
                    const val = (a.target.value).replace(/[^\d]+/g, '');
                    setValues((prevState) => ({ ...prevState, AgenciaDoc: val }));
                  }}
                  name="Agencia"
                />
                <BuscaEmpresa empresa={Agencia} />
              </InputGroup>
              <Form.Text className="text-muted">
                {`Agencia: ${Values.AgenciaId} - ${Values.Agencia_nome}`}
              </Form.Text>
            </Form.Group>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label>Data prevista</Form.Label>
                <Form.Control
                  disabled={Values.vali_id === '6'}
                  required
                  size="sm"
                  type="datetime-local"
                  value={Values.prevista}
                  name="dt_solicitacao"
                  onChange={
                    (a) => {
                      setValues((prevState) => ({ ...prevState, prevista: a.target.value }));
                    }
                  }
                  min={hoje}
                />
                <Form.Text className="text-muted">
                  Previsão de atracação
                </Form.Text>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Operação </Form.Label>
                <Form.Select
                  value={Values.operacaoId}
                  size="sm"
                  onChange={(a) => {
                    setValues((prevState) => ({ ...prevState, operacaoId: a.target.value }));
                  }}
                >
                  {Ope.map((item) => (
                    <option key={item.id} value={item.id}>{item.titulo}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Peso</Form.Label>
                <Form.Control Value={Values.peso} readOnly />
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <ListaManifesto Manifestos={Values.manifestos} Escala={Values.Escala} hidden={tab} />
      </Card.Body>
      <Card.Footer>
        <ButtonGroup className="mb-2">
          <Button variant="secondary" onClick={() => { navigate(-1); }}>
            Cancelar
          </Button>
          <Button variant={botao.tipo} type="submit" form="formulario">
            {botao.titulo}
          </Button>
        </ButtonGroup>
      </Card.Footer>
    </Card >
  );
}
