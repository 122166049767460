/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Button, Form, Card, Table, Row, Col,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import EmpresaCnpj from './3.2_EmpresaPorCnpj';
import config from '../Components/config/config';
import { docto } from '../Components/formatos';
import Paginacao from '../Components/Paginacao';

export default function Empresas() {
  const [Sel, setSel] = useState();
  const Selecionar = (emp) => {
    setSel('');
    setTimeout(() => {
      setSel(emp.Doc);
    }, 100);
  }
  const [Bempresas, setBempresas] = useState([{ id: '' }]);
  const [Consulta, setConsulta] = useState([{ id: '' }]);
  const navigate = useNavigate();
  const [CurrentPage, setCurrentPage] = useState(0);
  const handleInputChange = (event) => {
    const { value } = event.target;
    setCurrentPage(0);
    setConsulta(() => {
      if (value.length > 0) {
        return Bempresas.filter((empresa) => empresa.RazaoSocial && (empresa.RazaoSocial.toLowerCase().includes(value.toLowerCase())));
      }
      return Bempresas;
    });
  };


  // paginação
  const [itensPerPage, setItensPerPage] = useState(10);
  const pages = Math.ceil(Consulta.length / itensPerPage);
  const startIndex = CurrentPage * itensPerPage;
  const endIndex = startIndex + itensPerPage;
  const currentIntens = Consulta.slice(startIndex, endIndex);

  async function ListarEmpresas() {
    const Authorization = config.Key;
    await fetch(`${config.urlRootNode}Emp/Listar_empresas`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
    }).then(response => {
      if (!response.ok) {
        throw new Error('Erro na resposta da rede');
      }
      return response.json();
    })
      .then(data => {
        setBempresas(data);
        setConsulta(data);
      })
      .catch(error => {
        console.error('Houve um problema com a solicitação fetch:', error);
      });
  }
  useEffect(() => {
    ListarEmpresas();
    setItensPerPage(10);
  }, []);
  return (
    <Card>
      <Card.Header as="h3">
        <Row>
          <Col sm={1}>
            <Button onClick={() => { navigate(-1); }}>Voltar</Button>
          </Col>
          <Col sm={10}>
            Empresas cadastradas
          </Col>
          <Col sm={1}>
            <EmpresaCnpj emp={Sel} />
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Empresa</Form.Label>
            <Form.Control type="text" autoComplete="false" onChange={handleInputChange} placeholder="Nome fantasia" />
          </Form.Group>
          <Table responsive striped hover size="sm" >
            <thead>
              <tr>
                <th>Cnpj</th>
                <th>Nome</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              {typeof currentIntens !== 'undefined' && currentIntens.map((item, index) => {
                let nome;
                if (item.NomeFantasia === item.RazaoSocial) {
                  nome = item.RazaoSocial;
                } else nome = `${item.NomeFantasia} - ${item.RazaoSocial}`;
                return (
                  <tr key={index}>
                    <td>{docto(item.Doc)}</td>
                    <td>{nome}</td>
                    <td><Button style={{ width: 60, padding: 1, borderRadius: 20 }} size='sm' onClick={() => Selecionar(item)}><i className='bi bi-eye' /> </Button></td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Row>
        <Paginacao
          currentPage={CurrentPage}
          setCurrentPage={setCurrentPage}
          pages={pages}
          itemsPerPage={itensPerPage}
          setItemsPerPage={setItensPerPage}
        />
      </Card.Body>
    </Card>
  );
}
