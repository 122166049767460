/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Alert, Badge, Card, Col, Form, ListGroup, Row, Spinner, Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { dataEdi } from './separador';
import { datetimeLocal, docto, formatarValorQuilograma } from '../../../Components/formatos';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  getDatabase, ref, set,
} from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import EscalaPdf from './RelatorioEDI/EscalaPdf';
import config from '../../../Components/config/config';

export default function Siscarga({ dados }) {
  const [show, setShow] = useState(false);
  const [Escala, setEscala] = useState([]);
  const [arqv, setArquivo] = useState();
  const [Hidden, setHidden] = useState(false);
  const [erro, setErros] = useState({ show: false, erros: [] })
  const [pdf, setPdf] = useState('arquivo.pdf');
  const handleShow = () => setShow(true);
  const handleClose = () => (
    // eslint-disable-next-line no-sequences
    setShow(false),
    setHidden(false),
    setErros({ show: false, erros: [] }),
    setEscala([]))
  /* 
    https://loja.serpro.gov.br/integracomexterceiro 
    https://doc-siscomex-sapi.estaleiro.serpro.gov.br/integracomex/documentacao/carga/
    após contrato, montar recurso para uso da api
    */


  async function lerTexto(text) {
    setArquivo(text);
    const Authorization = config.Key;
    await fetch(`${config.urlRootNode}Documentos/LerEdi`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
      body: JSON.stringify({ Edi: text }),
    }).then(response => {
      if (!response.ok) {
        throw new Error('Erro na resposta da rede');
      }
      return response.json();
    })
      .then(data => {
        setPdf('Escala.pdf')
        setEscala(data.Escalas);
        setErros(() => {
          if (data.erros.length > 0) {
            return ({ show: true, erros: data.erros })
          } else {
            return ({ show: false, erros: [] })
          }
        })
      })
      .catch(error => {
        console.error('Houve um problema com a solicitação fetch:', error);
      });
    ;
  }

  function deletarFirebase(escala) {
    const db = getDatabase();
    set(ref(db, `cop/edi/${escala}`), {});
    handleClose();
  }
  const deletar = () => { deletarFirebase(Escala[0].numero) }
  function lerEdi(arquivo) {

    if (arquivo === undefined) {
      return (false)
    }

    const reader = new FileReader();
    reader.readAsText(arquivo, 'UTF-8'); // Defina a codificação como UTF-8
    reader.onloadend = async () => {
      const text = reader.result;
      lerTexto(text);
    }
  }
  function salvarFirebase() {
    const db = getDatabase();
    const solicit = {
      dat_emissao: Date.now(),
      EDI: arqv,
      Escala,
      numero: Escala[0].numero
    };
    set(
      ref(db, `cop/edi/${Escala[0].numero}`),
      solicit,
    );
    setShow(false);
    setTimeout(() => {
      setEscala([]);
    }, 100);
  }
  useEffect(() => {
    setShow(false)
    setHidden(false);
    if (dados && dados.EDI) {

      setHidden(true);
      setShow(true);
      lerTexto(dados.EDI);
    }
  }, [dados])

  const Arquivar = () => {
    salvarFirebase();
  }
  const navigate = useNavigate();

  async function RegistrarIntegracao() {
    const Authorization = config.Key;
    const reqs = await fetch(`${config.urlRootNode}Documentos/CadIntegracao`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
      body: JSON.stringify({
        Escala: Escala[0].numero,
        Previsao: datetimeLocal(dataEdi(Escala[0].previsao))
      }),
    });
    if (reqs.status === 200) {
      return true
    } else {
      return false
    }
  }

  async function RegistrarOperacao() {
    const escala = {
      ...Escala[0],
      Escala: Escala[0].numero,
    }
    const db = getDatabase();
    set(ref(db, `cop/Escala/${Escala[0].numero}`), escala,);

    const integrar = await RegistrarIntegracao();

    if (!integrar) {
      erro.erros.push(`não foi possivel registrar integração da escala ${Escala[0].numero}`);
    } else {
      setShow(false);
      deletar()
      setTimeout(() => {
        navigate('/Listaope')
      }, 1000);
    }
  }
  const Registrar = () => {
    RegistrarOperacao()
  }

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Leitura Siscarga
      </Button>

      <Modal show={show} onHide={handleClose} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>Leitura siscarga</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group hidden={Hidden} controlId="formFile" className="mb-3">
            <Form.Label>Default file input example</Form.Label>
            <Form.Control type="file" onChange={(e) => lerEdi(e.target.files[0])} />
          </Form.Group>
          {Escala !== undefined && Escala.map((escala) => {
            return (
              <Card>
                <Card.Header className='link' onClick={() => window.open('https://www4c.receita.fazenda.gov.br/carga-web/ApresentarDadosEscala.do?nrEscala=' + escala.numero)}>MV: {escala.navio} V.{escala.viagem}| IMO: {escala.imo} | Escala: {escala.numero} | ATR: {dataEdi(escala.previsao)} | <Badge>{formatarValorQuilograma(escala.peso)}</Badge> |  </Card.Header>
                <Card.Body>
                  <Alert show={erro.show} variant='danger' onClose={() => setErros((e) => ({ ...e, show: false }))} dismissible>
                    <Alert.Heading>Um erro encontrado</Alert.Heading>
                    {erro.erros && erro.erros.map((e, err) => (
                      <li key={`e${err}`}>{e}</li>
                    ))}
                  </Alert>

                  <Row md={2}>
                    {escala.manifestos.map((manifesto) => (
                      <Col md={escala.manifestos.length === 1 ? 12 : ''}>
                        <Card className='mb-3'>
                          <Card.Header className='link' onClick={() => window.open('https://www4c.receita.fazenda.gov.br/carga-web/ConsultarManifestoMaritimoPorNumero.do?nrManifesto=' + manifesto.manifesto)} >Manifesto {manifesto.manifesto} | <Badge>{formatarValorQuilograma(manifesto.peso)}</Badge></Card.Header>
                          <Card.Body>
                            <ListGroup className='mb-3'>
                              <ListGroup.Item>Agencia: {docto(manifesto.AgenciaDoc)} - {manifesto.AgenciaNome}</ListGroup.Item>
                              <ListGroup.Item>Armador: {manifesto.ArmadorNome}</ListGroup.Item>
                              <ListGroup.Item> Embarque: {manifesto.portoeCod} - {manifesto.PortoeNome}</ListGroup.Item>
                              <ListGroup.Item> Desembarque: {manifesto.portodCod} - {manifesto.PortodNome}</ListGroup.Item>
                              <ListGroup.Item>{manifesto.TrafegoCod} - {manifesto.TrafegoNome} </ListGroup.Item>
                            </ListGroup>
                            <Row md={1}>
                              {manifesto.cemercantes.map((ce, index) => (
                                <Col key={index}>
                                  <Card className='mb-3'>
                                    <Card.Header key={ce.CeNumero} className='link' onClick={() => window.open('https://www4c.receita.fazenda.gov.br/carga-web/ConsultarDadosBasicosCEMercante.do?nrCE=' + ce.CeNumero)}>CeMercante {ce.CeNumero} | <Badge>{formatarValorQuilograma(ce.peso)}</Badge></Card.Header>
                                    <Card.Body>
                                      <ListGroup>
                                        <ListGroup.Item variant={!ce.tipoConhec ? 'danger' : ''} title="TIPO DO CONHECIMENTO (A04) 'BL','MBL','HBL','MHBL'">{ce.tipoConhec}: {ce.BLOrig}</ListGroup.Item>
                                        <ListGroup.Item>Categoria: {ce.CatCarga}</ListGroup.Item>
                                        <ListGroup.Item>Situação: {ce.SituCarga}</ListGroup.Item>
                                        <ListGroup.Item>Data Situação: {dataEdi(ce.dataSit)}</ListGroup.Item>
                                        <ListGroup.Item>OrigemCod: {ce.OrigemCod} - {ce.PortoEmbarque}</ListGroup.Item>
                                        <ListGroup.Item>DestinoCod: {ce.DestinoCod} - {ce.Desembarque}</ListGroup.Item>
                                        <ListGroup.Item>Descrição: {ce.descricao}</ListGroup.Item>
                                        <ListGroup.Item>Beneficiario: {docto(ce.ConsgDoc)} - {ce.ConsgNome}</ListGroup.Item>
                                      </ListGroup>
                                      <Table responsive striped bordered hover size='sm'>
                                        <thead>
                                          <tr>
                                            <th>Item</th>
                                            <th>Descrição</th>
                                            <th>NCM</th>
                                            <th>Peso</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {ce.items.map((item, indexice) => {
                                            let descricao = 'asd';
                                            let cor = '';
                                            let ncm = item.ncms[0] && item.ncms[0].codmerc ? `${item.ncms[0].codmerc}` : '';
                                            let ncmdescr = item.ncms[0] && item.ncms[0].codmerc ? `${item.ncms[0].codmerc} - ${item.ncms[0].descr}` : '';
                                            switch (item.codtp) {
                                              case '1':
                                                descricao = ''
                                                break;
                                              case '2':
                                                /* carga solta */
                                                descricao = `${item.tipo} | ${parseFloat(item.cargaSoltaqtd > 0 ? item.cargaSoltaqtd : '0')} ${item.cargaSoltaTpEmb} | ${item.VeicMarca} | ${item.contraMarca}`
                                                cor = 'table-info';
                                                break;
                                              case '3':
                                                /* carga solta */
                                                descricao = `${item.tipo.toUpperCase()} ${item.grtp} | ${ce.descricao}`;
                                                break;
                                              case '4':
                                                descricao = `${item.tipo} | ${item.VeicChassi} | ${item.VeicMarca} | ${item.contraMarca}`;
                                                break;
                                              default:
                                                descricao = item.codtp
                                                break;
                                            }
                                            return (
                                              <tr key={`item${indexice}`} className={cor}>
                                                <th>{parseInt(item.item)}</th>
                                                <td>{descricao}</td>
                                                <td title={ncmdescr} className={item.ncms[0] ?? 'table-danger'}>{ncm}</td>
                                                <td>{formatarValorQuilograma(parseFloat(item.Pbruto))}</td>
                                              </tr>
                                            )
                                          })}
                                        </tbody>
                                      </Table>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              ))}
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Card.Body>
              </Card>
            )
          })}
        </Modal.Body>
        <Modal.Footer>
          <PDFDownloadLink hidden={!Hidden} document={<EscalaPdf escala={Escala} />} fileName={pdf}>
            {({
              blob, url, loading, error,
            }) => (loading ?
              <Button disabled><Spinner size='sm' /> Carregando...</Button>
              :
              <Button>Baixar documento</Button>
            )}
          </PDFDownloadLink>
          <Button variant='danger' hidden={!(dados && dados.EDI)} onClick={deletar}>
            <i className="bi bi-trash" /> apagar
          </Button>
          <Button variant="primary" onClick={Arquivar}>
            <i className="bi bi-save" /> arquivar
          </Button>
          <Button disabled={erro.erros.length > 0} variant="success" onClick={Registrar}>
            Registrar Escala
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  )
}