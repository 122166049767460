import React from 'react';
import {
  Button,
  Card, Col, Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function Documentos() {
  const navigate = useNavigate();
  return (
    <>
      <Card>
        <Card.Header as="h3">
          <Row>
            <Col sm={11}>
              Documentos
            </Col>
            <Col sm={1}>
              <Button
                onClick={() => navigate('cadastro')}
              >Novo</Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
        </Card.Body>
      </Card>
    </>
  );
}
