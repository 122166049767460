/* eslint-disable max-len */
import React from 'react';
import { Pagination } from 'react-bootstrap';

function Paginacao({
  currentPage, setCurrentPage, pages, itemsPerPage, setItemsPerPage,
}) {
  const renderPages = () => {
    const startPage = Math.max(0, currentPage - 2);
    const endPage = Math.min(startPage + 4, pages - 1);
    return Array.from(Array(endPage - startPage + 1), (_, index) => {
      const pageIndex = startPage + index;
      const isActive = currentPage === pageIndex;

      return (
        <Pagination.Item
          active={isActive}
          onClick={() => setCurrentPage(pageIndex)}
          key={pageIndex}
        >
          {pageIndex + 1}
        </Pagination.Item>
      );
    });
  };
  return (
    <Pagination size="sm">
      <Pagination.Item disabled>Página:</Pagination.Item>
      {currentPage > 2 && <Pagination.Item onClick={() => setCurrentPage(0)} key={0}>{1}</Pagination.Item>}
      {currentPage > 3 && <Pagination.Ellipsis />}
      {renderPages()}
      {currentPage < pages - 4 && <Pagination.Ellipsis />}
      {currentPage < pages - 3 && <Pagination.Item onClick={() => setCurrentPage(pages - 1)} key={pages - 1}>{pages}</Pagination.Item>}

      <Pagination.Item disabled>Itens:</Pagination.Item>
      {Array.from(Array(3), (_, index) => {
        const num = index + 1;
        const isActive = itemsPerPage === num * 10;
        return (
          // eslint-disable-next-line no-unused-expressions, no-sequences
          <Pagination.Item active={isActive} onClick={() => { setCurrentPage(0), setItemsPerPage(num * 10); }} key={`item-${num}`}>
            {num * 10}
          </Pagination.Item>
        );
      })}
    </Pagination>
  );
}

export default Paginacao;
