/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Table,
  Row,
  Col,
  Card,
  Modal,
  Button,
  ButtonGroup,
} from 'react-bootstrap';
import { docto, formatarValorQuilograma } from '../../Components/formatos';
import { orgItem } from '../../05_Documento/5.0_Componentes/Siscarga/separador';
import { useNavigate } from 'react-router-dom';

export default function ItensCe({ CeMercante }) {
  const navigate = useNavigate();
  const base = {
    CeNumero: '', ConsgDoc: '', ConsgNome: '', peso: 0, items: [], seq: 'falso'
  }
  const [show, setShow] = useState(false);
  const [Ce, setCe] = useState(base);
  const handleShow = () => setShow(true);
  const handleClose = () => (
    // eslint-disable-next-line no-sequences
    setShow(false),
    setCe(base))
  useEffect(() => {
    handleClose();
    if (CeMercante && CeMercante.items) {
      setCe({ ...base, ...CeMercante });
      handleShow();
    }
  }, [CeMercante])
  return (
    <>
      <Modal show={show} onHide={handleClose} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>Ce Mercante: {Ce.CeNumero}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row md={4}>
            <Col className='mb-3' md={6}><Card><Card.Body><Card.Title>Beneficiario</Card.Title>{docto(Ce.ConsgDoc)} - {Ce.ConsgNome}</Card.Body></Card></Col>
            <Col className='mb-3'><Card><Card.Body><Card.Title>Itens</Card.Title>{Ce.items.length}</Card.Body></Card></Col>
            <Col className='mb-3'><Card><Card.Body><Card.Title>Peso</Card.Title>{formatarValorQuilograma(Ce.peso)}</Card.Body></Card></Col>
            <Col md={12} className='mb-3'><Card><Card.Body><Card.Title>Descrição</Card.Title>{Ce.descricao}</Card.Body></Card></Col>
          </Row>
          <Table responsive hover striped size='sm'>
            <thead>
              <tr>
                <th>Item</th>
                <th>Descrição</th>
                <th>Ncm</th>
                <th>Peso</th>
              </tr>
            </thead>
            <tbody>
              {Ce !== "indefined" && (Ce.items.length > 0) && Ce.items.map((item, index) => {
                const org = orgItem(item, Ce.descricao)
                return (
                  <>
                    <tr className={org.cor}>
                      <th>{Ce.seq}</th>
                      <th>{parseInt(item.item)}</th>
                      <td>{org.descricao}</td>
                      <td title={org.ncmdescr} className={item.ncms[0] ?? 'table-danger'}>{org.ncm}</td>
                      <td>{formatarValorQuilograma(parseFloat(item.Pbruto))}</td>
                    </tr>
                  </>
                )
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>Fechar</Button>
          <Button hidden={Ce.seq === 'falso'} variant='warning' onClick={() => navigate(`${Ce.seq}`)}>Editar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
