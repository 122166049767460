import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import Logo from '../logo192.png';
import './estilo.css';

export default function LogoCentro() {
  const [konamiCode, setKonamiCode] = useState('');
  const konamiSequence = 'arrowuparrowuparrowdownarrowdownarrowleftarrowrightarrowleftarrowrightba';
  const [Class, setClass] = useState('');
  useEffect(() => {
    const handleKeyDown = (event) => {
      const key = event.key !== undefined ? event.key.toLowerCase() : '';
      setKonamiCode((prevCode) => prevCode + key);
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const checkCodeValidity = () => {
      if (konamiCode.endsWith(konamiSequence)) {
        setClass('rotate-div');
        setTimeout(() => { setClass(''); }, 5000);
        setKonamiCode('');
      } else if (konamiSequence.indexOf(konamiCode) !== 0) { setKonamiCode(''); }
    };

    checkCodeValidity();
  }, [konamiCode]);

  return (
    <div className="logoCentro">
      <Image className={Class} src={Logo} width={70} height={40} />
    </div>
  );
}
