/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Button, Form, Modal, InputGroup, Row, Col, Alert,
} from 'react-bootstrap';
import config from '../Components/config/config';
import { CheckCnpj, docto } from '../Components/formatos';

export default function EmpresaCnpj({ emp }) {
  const base = {
    Doc: '', RazaoSocial: '', NomeFantasia: '',
    Cep: '', Email: '', Form: false, Nac: true
  }

  const baseerro = { show: false, erro: [] }
  const [Cons, setCons] = useState(base);
  const [Message, setMessage] = useState(baseerro)
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setCons(base);
    setShow(false)
  };
  useEffect(() => {
    if (emp) {
      setCons((e) => ({ ...e, Doc: emp }));
      handleShow();
      BuscarEmpresa(emp);
    }
  }, [emp])
  const handleShow = () => setShow(true);
  async function BuscarEmpresa(valor) {
    const CnpjBuscado = valor.replace(/\D/g, '');
    const Authorization = config.Key;
    const reqs = await fetch(`${config.urlRootNode}Emp/Buscar_empresa`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
      body: JSON.stringify({ CnpjBuscado }),
    });
    if (reqs.status !== 200) {
      setCons({ ...base, Form: true });
      setMessage((m) => ({ ...m, show: true }));
      Message.erro.push('Documento não encontrado, cadastre manualmente')
    } else {
      const emp = await reqs.json()
      setCons({ ...emp, Form: true, Nac: CheckCnpj(emp.Doc) });
    }
  }
  async function Salvar() {

  }

  return (
    <>
      <Button variant="primary" size="sm" onClick={handleShow}>
        Cadastro
      </Button>
      <Modal backdrop="static" show={show} onHide={handleClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Cadastro de empresas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Alert variant='warning' show={Message.show}>
              <Alert.Heading>Erro</Alert.Heading>
              {Message.erro && Message.erro.map((m) => (<li>{m}</li>))}
            </Alert>
            <Row className="mb-3">
              <Form.Group as={Col} className="mb-3" controlId="cnpj">
                <InputGroup>
                  <InputGroup.Text>{Cons.Nac ? 'Cnpj' : 'Codigo'}</InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={docto(Cons.Doc)}
                    maxLength="18"
                    placeholder={`Preencha o ${Cons.Nac ? 'Cnpj' : 'Codigo'}`}
                    onChange={(e) => {
                      setCons((a) => ({ ...a, Doc: e.target.value }));
                      if (CheckCnpj(e.target.value)) {
                        BuscarEmpresa(e.target.value)
                      }
                    }}
                  />
                </InputGroup>
                <Form.Text className="text-muted">
                  Preencha somente os numeros - o cadastro será feito no ato da consulta
                </Form.Text>
              </Form.Group>
              <Form.Group as={Col} md={3}>
                <InputGroup>
                  <InputGroup.Checkbox checked={Cons.Nac} onChange={(e) => setCons((a) => ({ ...a, Nac: e.target.checked, Form: !e.target.checked }))} />
                  <Form.Control readOnly value={Cons.Nac ? 'Nacional' : 'Internacional'} />
                </InputGroup>
              </Form.Group>
            </Row>
            <div hidden={!Cons.Form}>
              <Form.Group className="mb-3">
                <InputGroup>
                  <InputGroup.Text>Razao</InputGroup.Text>
                  <Form.Control defaultValue={Cons.RazaoSocial} onChange={(e) => setCons((a) => ({ ...a, RazaoSocial: e.target.value }))} type="text" placeholder="" />
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <InputGroup>
                  <InputGroup.Text>Fantasia</InputGroup.Text>
                  <Form.Control defaultValue={Cons.NomeFantasia} onChange={(e) => setCons((a) => ({ ...a, NomeFantasia: e.target.value }))} type="text" placeholder="" />
                </InputGroup>
              </Form.Group>
              <Row>
                <Form.Group as={Col} className="mb-3">
                  <InputGroup >
                    <InputGroup.Text>Cep</InputGroup.Text>
                    <Form.Control defaultValue={docto(Cons.Cep)} onChange={(e) => setCons((a) => ({ ...a, Cep: e.target.value }))} type="text" placeholder="Cep" />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} className="mb-3" >
                  <InputGroup >
                    <InputGroup.Text>Email</InputGroup.Text>
                    <Form.Control defaultValue={Cons.Email} onChange={(e) => setCons((a) => ({ ...a, Email: e.target.value }))} type="text" placeholder="Email Principal" />
                  </InputGroup>
                </Form.Group>
              </Row>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button hidden={!Cons.Form} onClick={Salvar} variant="primary">
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
