import React, { useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";

export default function BuscaMan({ Man }) {
  const [show, setShow] = useState(false);
  const abrir = () => setShow(true);
  const fechar = () => setShow(false);
  return (<>
    <Button onClick={abrir}>Adicionar</Button>
    <Modal backdrop="static" show={show} onHide={fechar}>
      <Modal.Header closeButton>Selecionar manifesto</Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Manifesto</Form.Label>
            <InputGroup size="sm">
              <Form.Control />
              <Button variant="outline-secondary">Buscar</Button>
            </InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  </>)
}