import React, { useState } from 'react';
import {
  Button, Form, Modal, ListGroup, Badge,
} from 'react-bootstrap';
import config from '../../Components/config/config';

export default function BuscarPorto({ porto = '', disa = false }) {
  const [Busca, setBusca] = useState('');
  const [Cons, setCons] = useState();

  // modal 1
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  async function ProcurarPorto(porto) {
    const Authorization = config.Key;
    const reqs = await fetch(`${config.urlRootNode}geral/Buscar_porto`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
      body: JSON.stringify({ porto }),
    });
    setCons(await reqs.json());
  }
  function selecionar(dados) {
    porto(dados);
    handleClose();
  }
  return (
    <>
      <Button disabled={disa} variant="outline-secondary" onClick={handleShow}>
        Buscar
      </Button>

      <Modal backdrop="static" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Buscar Porto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="Doc">
            <Form.Label>Porto</Form.Label>
            <Form.Control
              type="text"
              placeholder="Porto"
              onChange={(e) => {
                setBusca(e.target.value);
              }}
            />
          </Form.Group>
          <ListGroup>
            {typeof Cons !== 'undefined' && Cons.map((p) => (
              <ListGroup.Item key={p.id} action className="d-flex justify-content-between align-items-start" onClick={() => selecionar(p)}>
                <div className="ms-2 me-auto">
                  <div key={p.id}>
                    {p.Porto} -  {p.Pais}
                  </div>
                </div>
                <Badge bg="primary" pill>
                  {(p.Codigo)}
                </Badge>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={() => ProcurarPorto(Busca)}>
            Buscar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
