import React, { useEffect, useState } from "react";
import { docto, formatarValorQuilograma } from "../../Components/formatos";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import BuscaMan from "./5.0.3_busca_Man";
import { useNavigate } from "react-router-dom";

export default function ListaManifesto({ Manifestos, Escala, hidden }) {
  const [Man, setMan] = useState([])
  const navigate = useNavigate();
  useEffect(() => {
    if (Manifestos.length > 0) {
      setMan(Manifestos)
    }
  }, [Manifestos])
  return (
    <Card hidden={hidden}>
      <Card.Header>
        <Row>
          <Col md={11}>
            Manifestos
          </Col>
          <Col>
            <BuscaMan />
          </Col>
        </Row>
      </Card.Header>
      <Card.Text>
        <Table responsive striped size="sm">
          <thead>
            <tr>
              <th>Manifesto</th>
              <th>Agencia</th>
              <th>Armador</th>
              <th>Embarque</th>
              <th>Desembarque</th>
              <th>Operação</th>
              <th>Peso</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            {Man.map((m, index) => (<tr>
              <td>{m.manifesto}</td>
              <td title={docto(m.AgenciaDoc)}>{m.AgenciaNome}</td>
              <td>{m.ArmadorNome}</td>
              <td>{m.portoeCod} - {m.PortoeNome}</td>
              <td>{m.portodCod} - {m.PortodNome}</td>
              <td>{m.TrafegoCod} - {m.TrafegoNome}</td>
              <td>{formatarValorQuilograma(m.peso)}</td>
              <td><Button variant='info' size='sm' onClick={() => navigate(`/documentos/${Escala}/${index}`)}><i className='bi bi-eye' /></Button></td>
            </tr>))}
          </tbody>
        </Table>
      </Card.Text>
    </Card>
  )
}