import React, { useState } from 'react';
import {
  Button, Form, Modal, ListGroup, Badge,
} from 'react-bootstrap';
import config from '../Components/config/config';
import { docto } from '../Components/formatos';

export default function BuscaEmpresa({ empresa = '', disa = false }) {
  const [Busca, setBusca] = useState('');
  const [Cons, setCons] = useState();
  // const [Btn, setBtn] = useState(false);

  // modal 1
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  async function BuscarEmpresa(Fantasia) {
    const Authorization = config.Key;
    const reqs = await fetch(`${config.urlRootNode}Emp/Buscar_empresa_nome`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
      body: JSON.stringify({ Fantasia }),
    });
    setCons(await reqs.json());
  }
  function selecionar(Doc) {
    empresa(Doc);
    handleClose();
  }
  const acionarform = (event) => {
    event.preventDefault();
    event.stopPropagation(); // Stop the event from propagating
    BuscarEmpresa(Busca);
  };
  return (
    <>
      <Button disabled={disa} variant="outline-secondary" onClick={handleShow}>
        Buscar
      </Button>

      <Modal backdrop="static" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Buscar Empresa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="formEmpresa" onSubmit={acionarform}>
            <Form.Group className="mb-3" controlId="Doc">
              <Form.Label>Empresa</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome da Empresa"
                onChange={(e) => {
                  setBusca(e.target.value);
                }}
              />
            </Form.Group>
          </Form>
          <ListGroup>
            {typeof Cons !== 'undefined' && Cons.map((p) => (
              <ListGroup.Item key={p.id} action className="d-flex justify-content-between align-items-start" onClick={() => selecionar(p)}>
                <div className="ms-2 me-auto">
                  <div key={p.id}>
                    {p.NomeFantasia}
                  </div>
                </div>
                <Badge bg="primary" pill>
                  {docto(p.Doc)}
                </Badge>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" form='formEmpresa' onClick={acionarform}>
            Buscar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
