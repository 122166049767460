/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import { Link as RouterLink } from 'react-router-dom';

export default function DropMenu({ User, l, ico }) {
  const [open, setOpen] = useState(false);
  const [itens, setItens] = useState();
  useEffect(() => {
    setItens(l.itens);
  }, []);
  return (
    <>
      <Button
        hidden={User.nivel !== 1}
        onClick={() => setOpen(!open)}
        className="itmenu"
        variant="outline-primary"
        aria-expanded={open}
      >
        <i hidden={ico} className={l.icone} />
        <div hidden={!ico} className="text-truncate">
          <i className={l.icone} />
          {` ${l.grupo}`}
        </div>
      </Button>
      <Collapse in={open}>
        <div>
          {typeof itens !== 'undefined' && itens.map((i) => (
            <Button
              className="itmenu"
              variant="outline-secondary"
              key={i.evento}
              as={RouterLink}
              to={i.evento}
            >
              <i hidden={ico} className={i.icone} />
              <div hidden={!ico} className="text-truncate">
                <i className={i.icone} />
                {` ${i.titulo}`}
              </div>
            </Button>
          ))}
        </div>
      </Collapse>
    </>
  );
}
