/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './estilo.css';
import Ajuda from '../Ajuda/Ajuda';
import DropMenu from './botao';

export default function Navegacao({ User, classe, ico }) {
  // eslint-disable-next-line no-unused-vars
  const [Dados, setDados] = useState([
    {
      "id": 1,
      "titulo": "Dashboard",
      "grupo": "Dashboard",
      "unico": 0,
      "evento": "Dashboard",
      "icone": "bi bi-pie-chart-fill",
      "categoria": 4
    }, {
      "id": 2,
      "titulo": "Operações",
      "grupo": "Operacional",
      "unico": 1,
      "evento": "Listaope",
      "icone": "bi bi-boxes",
      "categoria": 4
    }, {
      "id": 3,
      "titulo": "Integrações",
      "grupo": "Integrações",
      "unico": 0,
      "evento": "Integracoes",
      "icone": "bi bi-file-earmark-check",
      "categoria": 4
    }, {
      "id": 3,
      "titulo": "Documentos",
      "grupo": "Documentações",
      "unico": 1,
      "evento": "documentos",
      "icone": "bi bi-file-earmark",
      "categoria": 4
    }, {
      "id": 3,
      "titulo": "Empresas",
      "grupo": "Cadastros",
      "unico": 1,
      "evento": "empresas",
      "icone": "bi bi-building",
      "categoria": 4
    }
  ]);
  const [Menu, setMenu] = useState([{}]);
  /* const Authorization = config.Key;
     async function BuscarMenu(usuario) {
      const reqs = await fetch(`${config.urlRootNode}Usuarios/Menu`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization,
        },
        body: JSON.stringify(usuario),
      });
      if (reqs.status === 200) {
        const ress = await reqs.json();
        setDados(ress);
      }
    } */
  /* useEffect(() => {
    const usuario = JSON.parse(localStorage.getItem('usuario'));
     BuscarMenu(usuario);
  }, []); */
  useEffect(() => {
    const reorganizarDados = (info) => {
      const resultado = {};
      info.forEach((item) => {
        const { grupo } = item;
        if (!resultado[grupo]) {
          resultado[grupo] = {
            ...item,
            itens: [],
          };
        }
        resultado[grupo].itens.push({ ...item, to: `${item.grupo}/${item.evento}` });
      });
      return Object.values(resultado);
    };
    const nmenu = reorganizarDados(Dados);
    setMenu(nmenu);
  }, [Dados]);
  return (
    <div className={`flex-column d-md-block bg-light sidebar ${classe}`}>
      {
        typeof Menu !== 'undefined' && Menu.map((l, index) => (
          l.unico === 0 ? (
            <Button variant="outline-primary" className="itmenu" as={RouterLink} key={index} eventkey={l.evento} to={l.evento}>
              <i hidden={ico} className={l.icone} />
              <div hidden={!ico} className="text-truncate">
                <i className={l.icone} />
                {` ${l.titulo}`}
              </div>
            </Button>
          ) : (
            <DropMenu User={User} ico={ico} l={l} key={index} />
          )))
      }
      <Ajuda ico={ico} key="ajuda" />
    </div>
  );
}
