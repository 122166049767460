import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './estilo.css';
import { Card, Form, Nav } from 'react-bootstrap';

export default function Ajuda({ ico }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Algo deu errado? Nos informe:</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card hidden>
            <Card.Body>
              <Form>
                <Form.Group>
                  <Form.Label>Seu email</Form.Label>
                  <Form.Control />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Assunto</Form.Label>
                  <Form.Control />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Sua Duvida</Form.Label>
                  <Form.Control as="textarea" rows={5} />
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Button>Enviar</Button>
            </Card.Footer>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
      <Button className="itmenu" variant="outline-primary" onClick={handleShow}>
        <i hidden={ico} className="bi bi-question-circle-fill" />
        <div hidden={!ico} className="text-truncate">
          <i className="bi bi-question-circle-fill" />
          {' Ajuda'}
        </div>
      </Button>
    </>
  );
}
