/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Table,
  Row,
  Col,
  Card,
} from 'react-bootstrap';
import { child, get, getDatabase, ref } from 'firebase/database';
import { useParams } from 'react-router-dom';
import { docto, formatarValorQuilograma } from '../Components/formatos';
import ItensCe from './7.0_Componentes/7.0.1_ItemCe';

export default function DescMaritma() {
  const { ope } = useParams();
  const [Sel, setSel] = useState({});
  const [operacao, setOpe] = useState({
    navio: '',
    imo: '',
    Escala: '',
    viagem: '',
    peso: '',
    manifestos: []
  });
  async function BuscarResv() {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `cop/Escala/${ope}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const value = snapshot.val();
          setOpe(value);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }
  useEffect(() => {
    BuscarResv()
  }, [])
  const selecionar = (e) => {
    setSel({})
    setTimeout(() => {
      setSel(e)
    }, 100);
  }
  return (
    <>
      <Card className='mt-3'>
        <Card.Header>
          Resumo Descarga
        </Card.Header>
        <Row className='mt-4 m-2'>
          <Table responsive striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Cliente</th>
                <th>Documento</th>
                <th>Vol | Peso Manifestado</th>
                <th>Vol | Peso Descarregado</th>
                <th>Vol | Peso Saldo</th>
              </tr>
            </thead>
            <tbody>
              {typeof operacao !== "undefined" && (() => {
                let totalGeral = 0;
                let volGeral = 0;
                return (
                  <>
                    {typeof operacao !== "undefined" && operacao.manifestos.map((ope, index) => {
                      let total = 0;
                      let vol = 0;
                      return (
                        <>
                          {!!ope.cemercantes && ope.cemercantes.map((ce, index) => {
                            total += ce.peso;
                            totalGeral += ce.peso;
                            volGeral += (ce.items.length ?? 0);
                            vol += ce.items.length;
                            return (
                              <tr key={ce.CeNumero} title={ce.descricao} onDoubleClick={() => selecionar(ce)}>
                                <td>{docto(ce.ConsgDoc)}</td>
                                <td>{ce.tipoConhec}: {ce.BLOrig}</td>
                                <td>{ce.items.length} - {formatarValorQuilograma(ce.peso)}</td>
                                <td>descarregado</td>
                                <td>Saldo</td>
                              </tr>
                            );
                          })}
                          <tr hidden={operacao.manifestos.length === 1}>
                            <th colSpan={2}>Total Ce</th>
                            <th>{vol} - {formatarValorQuilograma(total)}</th>
                            <th>descarregado</th>
                            <th>Saldo</th>
                          </tr>
                        </>
                      );
                    })}
                    <tr>
                      <th colSpan={2}>Total Geral:</th>
                      <th>{volGeral} - {formatarValorQuilograma(totalGeral)}</th>
                      <th>descarregado</th>
                      <th>saldo</th>
                    </tr>
                  </>
                );
              })()}

            </tbody>
          </Table>
        </Row>
        <Row className='justify-content-md-end m-3' md={5} >
          <Col>
            <Card>
              <Card.Header>Saída ao cliente</Card.Header>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>Armazenagens</Card.Header>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>Descarga geral</Card.Header>
            </Card>
          </Col>
        </Row>
        <ItensCe CeMercante={Sel} />
      </Card>
    </>
  );
}
