export function docto(value = '') {
  if (value === null || value === '') {
    return ('');
  }
  const cnpjCpf = value.replace(/\D/g, '');
  if (cnpjCpf.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  } if (cnpjCpf.length === 14) {
    return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
  } if (cnpjCpf.length === 8) {
    return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})/g, '$1.$2-$3');
  }
  return value;
}
export function datetimeLocal(dia) {
  const dt = new Date(dia);
  dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
  return dt.toISOString().slice(0, 16);
}
export function datetime(dia) {
  if (dia === null) {
    return ('');
  }
  const ndata = new Date(dia).toLocaleDateString('pt-br', {
    day: 'numeric', // numeric, 2-digit
    month: 'numeric', // numeric, 2-digit, long, short, narrow
    year: 'numeric', // numeric, 2-digit
    hour: 'numeric', // numeric, 2-digit
    minute: 'numeric', // numeric, 2-digit
    // second: 'numeric', // numeric, 2-digit
  });
  return (ndata);
}
export function data(dia) {
  if (dia === null) {
    return ('');
  }
  const ndata = new Date(dia).toLocaleDateString('pt-br');
  return (ndata);
}
export function CheckCpf(cpf) {
  const strCPF = cpf.replace(/[^\d]+/g, '');
  let Soma = 0;
  let Resto;
  if (strCPF === '00000000000') { return false; }
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 9; i++) {
    Soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
  }
  Resto = (Soma * 10) % 11;
  if ((Resto === 10) || (Resto === 11)) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10), 10)) { return false; }
  Soma = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 10; i++) {
    Soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
  }
  Resto = (Soma * 10) % 11;
  if ((Resto === 10) || (Resto === 11)) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11), 10)) { return false; }
  return true;
}

export function CheckCnpj(val) {
  if (val === undefined) { return false; }
  if (val === '') { return false; }
  const cnpj = val.replace(/[^\d]+/g, '');
  if (cnpj.length !== 14) { return false; }
  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === '00000000000000'
    || cnpj === '11111111111111'
    || cnpj === '22222222222222'
    || cnpj === '33333333333333'
    || cnpj === '44444444444444'
    || cnpj === '55555555555555'
    || cnpj === '66666666666666'
    || cnpj === '77777777777777'
    || cnpj === '88888888888888'
    || cnpj === '99999999999999'
  ) { return false; }

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;

  // eslint-disable-next-line no-plusplus
  for (let i = tamanho; i >= 1; i--) {
    // eslint-disable-next-line no-plusplus
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) { pos = 9; }
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  if (resultado !== parseInt(digitos.charAt(0), 10)) {
    return false;
  }

  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;

  // eslint-disable-next-line no-plusplus
  for (let i = tamanho; i >= 1; i--) {
    // eslint-disable-next-line no-plusplus
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  if (resultado !== parseInt(digitos.charAt(1), 10)) {
    return false;
  }

  return true;
}
export function placa(val = '') {
  return val.toUpperCase();
}
export function days(date1, date2) {
  const total = {};
  const text = {};
  const difference = date1.getTime() - date2.getTime();
  total.d = Math.round(difference / (1000 * 3600 * 24));
  total.h = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  total.m = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  total.h = total.h < 0 ? total.h + 1 : total.h;
  text.d = Math.abs(parseInt(total.d, 10)) < 10 ? `0${Math.abs(parseInt(total.d, 10))}` : Math.abs(parseInt(total.d, 10));
  text.h = Math.abs(parseInt(total.h, 10)) < 10 ? `0${Math.abs(parseInt(total.h, 10))}` : Math.abs(parseInt(total.h, 10));
  text.m = Math.abs(parseInt(total.m, 10)) < 10 ? `0${Math.abs(parseInt(total.m, 10))}` : Math.abs(parseInt(total.m, 10));
  text.d = text.d <= 1 ? `${text.d} dia, ` : `${text.d} dias, `;
  text.h = text.h <= 1 ? `${text.h} hora, ` : `${text.h} horas, `;
  text.m = text.m <= 1 ? ` ${text.m} minuto` : ` ${text.m} minutos`;
  total.text = (total.d !== 0 ? text.d : '') + (total.h !== 0 ? text.h : '') + (total.m !== 0 ? text.m : '');
  return total;
}
export function formatarValorReal(valor = 0) {
  let numero = parseFloat(valor);
  if (valor === 'undefined') { numero = 0; }
  if (valor === undefined) { numero = 0; }

  return numero.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
}

export function formatarValorQuilograma(valor) {
  let numero = 0;
  if (valor === 'undefined') { numero = 0; }
  if (valor === undefined) { numero = 0; }
  numero = parseFloat(valor);
  return numero.toLocaleString('pt-BR', { style: 'unit', unit: 'kilogram', minimumFractionDigits: 2 });
}
