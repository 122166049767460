import React from 'react';
import {
  Table,
  Row,
  Col,
  Card,
} from 'react-bootstrap';

export default function RelCop() {
  return (
    <>
      <Card className='mt-3'>
        <Card.Header>
          Relatorio operacional
        </Card.Header>
        <Row className='justify-content-md-center m-2'>
          <Col md={6}>
            <h4>Relatorio de periodos</h4>
            <Table responsive striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Seq</th>
                  <th>Data</th>
                  <th>Dia</th>
                  <th>periodo</th>
                  <th>Porão</th>
                  <th>paralização</th>
                  <th>Tempo</th>
                  <th>TTL Periodo</th>
                  <th>TTL Acumulado</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Seq</td>
                  <td>Data</td>
                  <td>Dia</td>
                  <td>periodo</td>
                  <td>Porão</td>
                  <td>paralização</td>
                  <td>Tempo</td>
                  <td>TTL Periodo</td>
                  <td>TTL Acumulado</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className='justify-content-md-center m-2'>
          <Col md={6}>
            <h4>Resumo</h4>
            <Table responsive striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Porão</th>
                  <th>Porões</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Plano de descarga</th>
                  <td>valores</td>
                  <td>Total</td>
                </tr>
                <tr>
                  <th>Descarregados por porão</th>
                  <td>valores</td>
                  <td>Total</td>
                </tr>
                <tr>
                  <th>Saldo por porão</th>
                  <td>valores</td>
                  <td>Total</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className='justify-content-md-center m-2'>
          <Col md={6}>
            <h4>CE mercante</h4>
            <Table responsive striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>CE Mercante</th>
                  <th>BL</th>
                  <th>Previsto</th>
                  <th>Operado</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>CE Mercante</td>
                  <td>BL</td>
                  <td>Previsto</td>
                  <td>Operado</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className='justify-content-md-center m-2'>
          <Col md={6}>
            <h4>Observação</h4>
            <Card>
              <Card.Body>
                Tempo da operação: __dias __horas __minutos<br />
                Tempo de paralização: __dias __horas __minutos<br />
                Tempo operando: __dias __horas __minutos<br />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card>
    </>
  );
}
