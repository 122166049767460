/* eslint-disable react-hooks/exhaustive-deps */
import { child, get, getDatabase, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react';
import {
  Card, Row, Col,
  ListGroup,
  ListGroupItem,
  Dropdown,
} from 'react-bootstrap';
import { Link as RouterLink, Outlet, useParams } from 'react-router-dom';
import { formatarValorQuilograma } from '../Components/formatos';

export default function OpMaritma() {

  const { ope } = useParams();
  const [operacao, setOpe] = useState({
    navio: '',
    imo: '',
    Escala: '',
    viagem: '',
    peso: '',
    manifestos: []
  });
  async function BuscarResv() {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `cop/Escala/${ope}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const value = snapshot.val();
          setOpe(value);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
    /* const reqs = await fetch(`${config.urlRootNode}Programacao/Buscar_prog`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
      body: JSON.stringify({ resv }),
    });
    if (reqs.status === 200) {
      const ress = await reqs.json();
      setValues((prevState) => ({ ...prevState, ...ress, vali_id: 1 }
      ));
    } */
  }
  useEffect(() => {
    BuscarResv()
  }, [])
  return (
    <>
      <Card>
        <Card.Header>
          <Row>
            <Col sm={10}>
              Operação
            </Col>
            <Col>
              <Dropdown >
                <Dropdown.Toggle variant="success" id="dropdown-basic" size='sm'>
                  Relatorios
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={RouterLink} to='resumo'>Resumo</Dropdown.Item>
                  <Dropdown.Item as={RouterLink} to='descarga'>Descarga</Dropdown.Item>
                  <Dropdown.Item as={RouterLink} to='sof'>Paralisações</Dropdown.Item>
                  <Dropdown.Item as={RouterLink} to='cop'>Relatorio operacional</Dropdown.Item>
                  <Dropdown.Item as={RouterLink} to={`/Listaope/${operacao.Escala}`}>Editar operação</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Card.Text>
            <Row>
              <Col sm={4}>
                <ListGroup>
                  <ListGroupItem>Navio: {operacao.imo} - {operacao.navio} V.{operacao.viagem}</ListGroupItem>
                  <ListGroupItem>Escala: {operacao.Escala}</ListGroupItem>
                  <ListGroupItem>Atracação: dd/mm/aa - dd/mm/aa - _dias  h:m </ListGroupItem>
                  <ListGroupItem>Operação: dd/mm/aa - dd/mm/aa - _dias  h:m </ListGroupItem>
                </ListGroup>
              </Col>
              <Col>
                <Row xs={3}>
                  <Col>
                    <Card>
                      <Card.Body>
                        <Card.Title>
                          Manifestado
                        </Card.Title>
                        <Row>
                          <Col md={1}>
                            <i className='bi bi-clipboard2-data' />
                          </Col>
                          <Col>
                            {formatarValorQuilograma(operacao.peso)}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <Card.Title>
                          Descarregado
                        </Card.Title>
                        <Row>
                          <Col md={1}>
                            <i className='bi bi-clipboard2-data' />
                          </Col>
                          <Col>
                            {formatarValorQuilograma(operacao.peso)}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <Card.Body>
                        <Card.Title>
                          Saldo
                        </Card.Title>
                        <Row>
                          <Col md={1}>
                            <i className='bi bi-clipboard2-data' />
                          </Col>
                          <Col>
                            {formatarValorQuilograma(operacao.peso)}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Text>
        </Card.Body>
      </Card>
      <Outlet />
    </>
  );
}
