import React, { useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import config from '../Components/config/config';
import CheckPassword from '../Components/Loading/progressPass';
import { CheckCpf, docto } from '../Components/formatos';

export default function RecuperaUsuario() {
  const [show, setShow] = useState(false);
  const [Input, setInput] = useState(false);
  const [Valid, setValid] = useState(false);
  const [Codigo, setCodigo] = useState({ id: '', Codigo: '' });
  const [User, setUser] = useState({ Password: '', Email: '', Cpf: '' });
  const [Code, setCode] = useState();
  const [Validacao, setValidacao] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Authorization = config.Key;
  async function Verificar(Valor) {
    if (Valor === Codigo) {
      setValidacao(false);
      setInput(true);
    } else {
      setValidacao(true);
      setInput(false);
    }
  }
  async function recuperar(dados) {
    const reqs = await fetch(`${config.urlRootNode}Usuarios/RecuperaUsuario?Email=${dados.Email}&Cpf=${dados.Cpf}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
    });
    if (reqs.status === 200) {
      const ress = await reqs.json();
      console.log(ress);
      setCodigo(ress);
      setInput(true);
    }
  }
  async function Salvar(Usuario) {
    console.log(Usuario);
    const reqs = await fetch(`${config.urlRootNode}Usuarios/Editar_usuario`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
      body: JSON.stringify(Usuario),
    });
    if (reqs.status === 200) {
      setInput(true);
    }
  }
  const ativar = (childdata) => {
    setValid(childdata);
  };
  return (
    <>
      <Button variant="secondary" onClick={handleShow}>
        Recuperar
      </Button>
      <Modal backdrop="static" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Recuperar Senha</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Endereço de Email</Form.Label>
            <Form.Control
              disabled={Input}
              isValid={Input}
              autoComplete="false"
              autoFocus
              type="email"
              placeholder="Entre com seu email"
              value={User.Email}
              onChange={(v) => setUser((prevState) => ({
                ...prevState,
                Email: v.target.value,
              }))}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="documento">
            <Form.Label>CPF</Form.Label>
            <Form.Control
              disabled={Input}
              autoComplete="false"
              autoFocus
              type="text"
              placeholder="Entre com seu CPF"
              value={User.doc}
              isValid={Input}
              isInvalid={!CheckCpf(User.Cpf)}
              onChange={(v) => setUser((prevState) => ({
                ...prevState,
                doc: docto(v.target.value),
                Cpf: v.target.value.replace(/[^\d]+/g, ''),
              }))}
            />
          </Form.Group>
          <Form.Group hidden={!Input} className="mb-3" controlId="codigo">
            <Form.Label>Codigo de confirmação</Form.Label>
            <Form.Control autoComplete="false" type="text" onChange={(v) => setCode(v.target.value)} />
          </Form.Group>
          {Validacao ? (
            <Form.Group hidden={!Validacao} className="mb-3" controlId="codigo">
              <Form.Label>Nova Senha</Form.Label>
              <Form.Control
                autoComplete="false"
                type="password"
                onChange={(v) => setCodigo((prevState) => ({
                  ...prevState,
                  Password: v.target.value,
                }))}
              />
              <p />
              <CheckPassword password={Codigo.Password} ativa={ativar} />
            </Form.Group>
          ) : ('')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button hidden={!Validacao ? Input : !Input} variant="primary" onClick={() => recuperar(User)}>
            Solicitar Codigo
          </Button>
          <Button hidden={!Input} variant="success" onClick={() => Verificar(Code)}>
            Verificar
          </Button>
          <Button hidden={!Validacao} disabled={!Valid} variant="info" onClick={() => Salvar(Codigo)}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
