import {
  Button, Form, Modal, InputGroup, ListGroup, Badge,
} from 'react-bootstrap';
import React, { useState } from 'react';

import config from '../Components/config/config';
import CadVeic from './Veiculo_form_cad';

export default function BuscarVeiculo({ placa = '', disa = '' }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Busca, setBusca] = useState();
  const [Result, setResult] = useState([]);
  const [Cad, setCad] = useState('secondary');

  async function BuscarPlaca(Valor) {
    const Authorization = config.Key;
    const reqs = await fetch(`${config.urlRootNode}Veiculos/Buscar_veiculo`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
      body: JSON.stringify({ placa: Valor }),
    });
    if (reqs.status === 200) {
      const ress = await reqs.json();
      setResult(ress);
      setCad('secondary');
    } else {
      setCad('warning');
    }
  }
  const veiculo = (childdata) => {
    setBusca(childdata);
    BuscarPlaca(childdata);
  };
  function selecionar(cpf) {
    placa(cpf);
    handleClose();
  }
  return (
    <>
      <Button disabled={disa} variant="outline-secondary" onClick={handleShow}>
        Buscar
      </Button>
      <Modal backdrop="static" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cadastro de veículo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text>Placa</InputGroup.Text>
            <Form.Control name="busca" value={Busca} autoComplete="false" onChange={(e) => setBusca(e.target.value)} aria-label="Small" />
          </InputGroup>
          <ListGroup>
            {typeof Result !== 'undefined' && Result.map((p) => (
              <ListGroup.Item action className="d-flex justify-content-between align-items-start" onClick={() => selecionar(p)}>
                <div className="ms-2 me-auto">
                  <div key={p.placa}>
                    {p.placa}
                  </div>
                </div>
                <Badge bg="primary" pill>
                  {p.tipo}
                </Badge>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <CadVeic variant={Cad} veiculo={veiculo} />
          <Button variant="primary" form="BuscaPessoa" onClick={() => BuscarPlaca(Busca)}>Buscar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
