// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { child, get, getDatabase, ref } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCIUYlldNXI9LhHE0GHYU8kQ5WkiJJu4Dg",
  authDomain: "lanescop.firebaseapp.com",
  databaseURL: "https://lanescop-default-rtdb.firebaseio.com",
  projectId: "lanescop",
  storageBucket: "lanescop.appspot.com",
  messagingSenderId: "461574379225",
  appId: "1:461574379225:web:60d9aff5fcc317806750c8",
  measurementId: "G-1B6PYF5514"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);


export async function getUser() {
  const uid = localStorage.getItem('user_uid');
  const dbRef = ref(getDatabase());
  return get(child(dbRef, `/cop/usuarios/${uid}`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const val = snapshot.val();
        val.uid = uid;
        return val;
      }
      throw new Error('Usuário não encontrado no Firebase.');
    })
    .catch((error) => {
      throw new Error(error);
    });
}

