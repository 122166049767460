/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Button, Form, Card, InputGroup, Row, Col, ButtonGroup, Spinner,
} from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { CheckCnpj, datetimeLocal, docto } from '../Components/formatos';

import BuscaEmpresa from '../03_Empresa/3.3_EmpresaPorNome';
import BuscarPorto from './5.0_Componentes/PortoNome';
import config from '../Components/config/config';
import { child, get, getDatabase, ref, set } from 'firebase/database';
import ListaCeReduzida from './5.0_Componentes/5.0.4.1_ListaCeReduzida';


export default function ManifestoForm() {
  const hoje = datetimeLocal(new Date());
  const Authorization = config.Key;
  const { Manifesto, Escala } = useParams();
  const [Loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [Ope, setOperacoes] = useState([{ id: '1', titulo: 'Descarga' }, { id: '2', titulo: 'Carga' },]);
  const navigate = useNavigate();
  const [Values, setValues] = useState({
    cadastro: hoje,
    manifesto: '',
    portoeCod: '',
    PortoeNome: '',
    portodCod: '',
    PortodNome: '',
    AgenciaBusca: false,
    AgenciaDoc: '',
    AgenciaNome: '',
    ArmadorDoc: '',
    ArmadorNome: '',
    TrafegoCod: '',
    TrafegoNome: '',
  });

  const [tab, setTab] = useState(true);
  const [botao, setBotao] = useState({});
  async function BuscarResv() {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `cop/Escala/${Escala}/manifestos/${Manifesto}/`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const value = snapshot.val();
          console.table(value.cemercantes)
          setValues(value);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
    /* const reqs = await fetch(`${config.urlRootNode}Programacao/Buscar_prog`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
      body: JSON.stringify({ resv }),
    });
    if (reqs.status === 200) {
      const ress = await reqs.json();
      setValues((prevState) => ({ ...prevState, ...ress, vali_id: 1 }
      ));
    } */
  }
  async function CadastraProgramacao() {
    setLoading(true)
    const db = getDatabase();
    set(ref(db, `cop/Manifesto/${Values.atr}`), Values);
    navigate(`/${Values.atr}`);
    /*  const reqs = await fetch(`${config.urlRootNode}Programacao/Nova_Programacao`, {
       method: 'POST',
       headers: {
         Accept: 'application/json',
         'Content-Type': 'application/json',
         Authorization,
       },
       body: JSON.stringify(Dados),
     });
     setLoading(false);
     if (reqs.status === 200) {
       // const ress = await reqs.json();
       setTimeout(() => {
         setLoading(true);
         navigate('/Agendamento');
       }, 3000);
     } */
  }
  useEffect(() => {
    if (Manifesto) {
      BuscarResv(Manifesto);
      setBotao({ titulo: 'Salvar', tipo: 'warning' });
      setTab(false);
    } else {
      setBotao({ titulo: 'Cadastrar', tipo: 'primary' });
      setTab(true);
    }
  }, [Manifesto]);
  async function EditaProgramacao(Dados) {
    /* const reqs = await fetch(`${config.urlRootNode}Programacao/Editar_Programacao`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
      body: JSON.stringify(Dados),
    });
    if (reqs.status === 200) {
      // const ress = await reqs.json();
      navigate('/Agendamento');
    } */
  }
  const EventoCadastro = (event) => {
    event.preventDefault();
    if (tab) {
      CadastraProgramacao(Values);
    } else {
      EditaProgramacao(Values);
    }
  };
  async function BuscaCnpj(valor) {
    const CnpjBuscado = valor.replace(/\D/g, '');
    const reqs = await fetch(`${config.urlRootNode}Emp/Buscar_empresa`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
      body: JSON.stringify({ CnpjBuscado }),
    });
    const dados = await reqs.json();
    return (dados);
  }
  useEffect(() => {
    if (CheckCnpj(Values.AgenciaDoc)) {
      setValues((prevState) => ({
        ...prevState, AgenciaBusca: true,
      }));
      BuscaCnpj(Values.AgenciaDoc).then((val) => {
        setValues((prevState) => ({
          ...prevState, AgenciaId: val.emp_id, AgenciaNome: val.NomeFantasia, AgenciaBusca: false,
        }));
      });
    } else {
      setValues((prevState) => ({
        ...prevState, AgenciaId: '', AgenciaNome: '',
      }));
    }
  }, [Values.AgenciaDoc]);

  const Agencia = (childdata) => {
    setValues((prevState) => ({
      ...prevState,
      AgenciaDoc: childdata.Doc,
    }));
  };
  const Armador = (childdata) => {
    setValues((prevState) => ({
      ...prevState,
      ArmadorId: childdata.id,
      ArmadorNome: childdata.NomeFantasia,
      ArmadorDoc: childdata.Doc,
    }));
  };
  const embarque = (child) => {
    setValues((prevState) => ({
      ...prevState,
      embId: child.child.id,
      embPorto: child.Porto,
      embPais: child.Pais,
      embCodigo: child.Codigo
    }))
  }
  const desembarque = (child) => {
    setValues((prevState) => ({
      ...prevState,
      desId: child.child.id,
      desPorto: child.Porto,
      desPais: child.Pais,
      desCodigo: child.Codigo
    }))
  }
  return (
    <Card>
      <Card.Header as="h3">{tab ? 'Cadastro de manifesto' : 'Editar manifesto'}</Card.Header>
      <Card.Text hidden={Loading} style={{ alignSelf: 'center' }}>
      </Card.Text>
      <Card.Body hidden={!Loading}>
        <Form id="formulario" onSubmit={EventoCadastro} className="mb-2">
          <Row>
            <Form.Group as={Col} md={3}>
              <Form.Label>manifesto</Form.Label>
              <Form.Control size='sm'
                name='manifesto'
                value={Values.manifesto}
                onChange={(e) => setValues((prev) =>
                  ({ ...prev, manifesto: e.target.value }))} />
            </Form.Group>
            <Form.Group as={Col} md={3}>
              <Form.Label>Agencia</Form.Label>
              <InputGroup size='sm'>
                <Form.Control
                  name='AgenciaDoc'
                  value={docto(Values.AgenciaDoc)}
                  onChange={(e) => setValues((prev) =>
                    ({ ...prev, AgenciaDoc: e.target.value }))} />
                <BuscaEmpresa empresa={Agencia} />
              </InputGroup>
              <Form.Text className="text-muted">
                <Spinner hidden={!Values.AgenciaBusca} variant="secondary" size='sm' />
                {Values.AgenciaId} - {Values.AgenciaNome}
              </Form.Text>
            </Form.Group>
            <Form.Group as={Col} md={3}>
              <Form.Label>ArmadorDoc</Form.Label>
              <InputGroup size='sm'>
                <Form.Control
                  name='ArmadorDoc'
                  value={docto(Values.ArmadorDoc)}
                  onChange={(e) => setValues((prev) =>
                    ({ ...prev, ArmadorDoc: e.target.value }))} />
                <BuscaEmpresa empresa={Armador} />
              </InputGroup>
              <Form.Text className="text-muted">
                {Values.ArmadorNome}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Porto Embarque</Form.Label>
              <InputGroup size='sm'>
                <Form.Control
                  name='embarque'
                  Value={Values.portoeCod}
                  onChange={(e) => setValues((prev) =>
                    ({ ...prev, portoeCod: e.target.value }))} />
                <BuscarPorto porto={embarque} />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Porto desembarque</Form.Label>
              <InputGroup size='sm'>
                <Form.Control
                  name='desembarque'
                  Value={Values.portodCod}
                  onChange={(e) => setValues((prev) =>
                    ({ ...prev, portodCod: e.target.value }))} />
                <BuscarPorto porto={desembarque} />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md={3}>
              <Form.Label>Trafego Nome</Form.Label>
              <Form.Control size='sm'
                name='TrafegoNome'
                Value={Values.TrafegoNome}
                onChange={(e) => setValues((prev) =>
                  ({ ...prev, TrafegoNome: e.target.value }))} />
            </Form.Group>
          </Row>
        </Form>
        {Values.cemercantes && <ListaCeReduzida Ces={Values.cemercantes} />}
        <ButtonGroup className="mb-2">
          <Button variant="secondary" onClick={() => { navigate(-1); }}>
            Cancelar
          </Button>
          <Button variant={botao.tipo} type="submit" form="formulario">
            {botao.titulo}
          </Button>
        </ButtonGroup>
      </Card.Body>
    </Card >
  );
}
