/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Button, Form, Card, ButtonGroup,
  Table,
  Row,
  Col,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { CheckCnpj, datetimeLocal, docto, formatarValorQuilograma } from '../Components/formatos';

import config from '../Components/config/config';
import { child, get, getDatabase, ref, set } from 'firebase/database';
import { orgItem } from './5.0_Componentes/Siscarga/separador';
import FormItem from '../07_Operacao/7.0_Componentes/7.0.1.1_Form_item';
import BuscaEmpresa from '../03_Empresa/3.3_EmpresaPorNome';
import BuscarPorto from './5.0_Componentes/PortoNome';

export default function CeMercanteForm() {
  const hoje = datetimeLocal(new Date());
  const Authorization = config.Key;
  const { Manifesto, Escala, Ce } = useParams();
  const [Loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [Ope, setOperacoes] = useState([{ id: '1', titulo: 'Descarga' }, { id: '2', titulo: 'Carga' },]);
  const navigate = useNavigate();
  const [Sel, setSel] = useState({});
  const [Values, setValues] = useState({
    items: [],
  });

  const [tab, setTab] = useState(true);
  const [botao, setBotao] = useState({});
  async function BuscarResv() {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `cop/Escala/${Escala}/manifestos/${Manifesto}/cemercantes/${Ce}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const value = snapshot.val();
          setValues(value);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
    /* const reqs = await fetch(`${config.urlRootNode}CeMercante/Buscar_prog`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
      body: JSON.stringify({ resv }),
    });
    if (reqs.status === 200) {
      const ress = await reqs.json();
      setValues((prevState) => ({ ...prevState, ...ress, vali_id: 1 }
      ));
    } */
  }
  async function CadastraCeMercante() {
    setLoading(true)
    const db = getDatabase();
    set(ref(db, `cop/Manifesto/${Values.atr}`), Values);
    navigate(`/${Values.atr}`);
    /*  const reqs = await fetch(`${config.urlRootNode}CeMercante/Nova_CeMercante`, {
       method: 'POST',
       headers: {
         Accept: 'application/json',
         'Content-Type': 'application/json',
         Authorization,
       },
       body: JSON.stringify(Dados),
     });
     setLoading(false);
     if (reqs.status === 200) {
       // const ress = await reqs.json();
       setTimeout(() => {
         setLoading(true);
         navigate('/Agendamento');
       }, 3000);
     } */
  }
  const selecionar = (item) => {
    setSel({});
    setTimeout(() => {
      setSel(item);
    }, 100);
  }
  const retIntem = (childdata) => {
    //atualizar lista principal
  };
  useEffect(() => {
    if (Ce) {
      BuscarResv();
      setBotao({ titulo: 'Salvar', tipo: 'warning' });
      setTab(false);
    } else {
      setBotao({ titulo: 'Cadastrar', tipo: 'primary' });
      setTab(true);
    }
  }, [Ce]);
  async function EditaCeMercante(Dados) {
    /* const reqs = await fetch(`${config.urlRootNode}CeMercante/Editar_CeMercante`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
      body: JSON.stringify(Dados),
    });
    if (reqs.status === 200) {
      // const ress = await reqs.json();
      navigate('/Agendamento');
    } */
  }


  async function BuscaCnpj(valor) {
    const CnpjBuscado = valor.replace(/\D/g, '');
    const reqs = await fetch(`${config.urlRootNode}Emp/Buscar_empresa`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
      body: JSON.stringify({ CnpjBuscado }),
    });
    const dados = await reqs.json();
    return (dados);
  }
  useEffect(() => {
    if (CheckCnpj(Values.ConsgDoc)) {
      setValues((prevState) => ({
        ...prevState, ConsgBusca: true,
      }));
      BuscaCnpj(Values.ConsgDoc).then((val) => {
        console.log(val);
        setValues((prevState) => ({
          ...prevState, ConsgId: val.emp_id, ConsgNome: val.NomeFantasia, ConsgBusca: false,
        }));
      });
    } else {
      setValues((prevState) => ({
        ...prevState, ConsgId: '', ConsgNome: '',
      }));
    }
  }, [Values.ConsgDoc]);

  const Beneficiario = (childdata) => {
    setValues((prevState) => ({
      ...prevState,
      ConsgDoc: childdata.Doc,
    }));
  };



  const embarque = (child) => {
    setValues((prevState) => ({
      ...prevState,
      embId: child.child.id,
      embPorto: child.Porto,
      embPais: child.Pais,
      embCodigo: child.Codigo
    }))
  }
  const desembarque = (child) => {
    setValues((prevState) => ({
      ...prevState,
      desId: child.child.id,
      desPorto: child.Porto,
      desPais: child.Pais,
      desCodigo: child.Codigo
    }))
  }





  const EventoCadastro = (event) => {
    event.preventDefault();
    if (tab) {
      CadastraCeMercante(Values);
    } else {
      EditaCeMercante(Values);
    }
  };
  const handleSave = (editedItem) => {
  };
  return (
    <Card>
      <Card.Header as="h3">
        <Row>
          <Col md={11}>
            {tab ? 'Cadastro de Ce Mercante' : 'Editar Ce Mercante'}
          </Col>
          <Col>
            <FormItem item={Sel} onSave={handleSave} />
          </Col>
        </Row>
      </Card.Header>
      <Card.Text hidden={Loading} style={{ alignSelf: 'center' }}>
      </Card.Text>
      <Card.Body hidden={!Loading}>
        <Form id="formulario" onSubmit={EventoCadastro} className="mb-2">
          <Row>
            <Form.Group as={Col} md={2}>
              <Form.Label>Ce Mercante</Form.Label>
              <Form.Control value={Values.CeNumero} />
            </Form.Group>
            <Form.Group as={Col} md={2}>
              <Form.Label>Consignatario / Beneficiario</Form.Label>
              <InputGroup size='sm'>
                <Form.Control
                  name='ConsgDoc'
                  value={docto(Values.ConsgDoc)}
                  onChange={(e) => setValues((prev) =>
                    ({ ...prev, ConsgDoc: e.target.value }))} />
                <BuscaEmpresa empresa={Beneficiario} />
              </InputGroup>
              <Form.Text className="text-muted">
                <Spinner hidden={!Values.ConsgBusca} variant="secondary" size='sm' />
                {Values.ConsgId} - {Values.ConsgNome}
              </Form.Text>
            </Form.Group>
            <Form.Group as={Col} md={1}>
              <Form.Label>Embarque</Form.Label>
              <InputGroup size='sm'>
                <Form.Control
                  name='embarque'
                  Value={Values.OrigemCod}
                  onChange={(e) => setValues((prev) =>
                    ({ ...prev, OrigemCod: e.target.value }))} />
                <BuscarPorto porto={embarque} />
              </InputGroup>
              <Form.Text>{Values.PortoEmbarque}</Form.Text>
            </Form.Group>
            <Form.Group as={Col} md={1}>
              <Form.Label>Desembarque</Form.Label>
              <InputGroup size='sm'>
                <Form.Control
                  name='desembarque'
                  Value={Values.DestinoCod}
                  onChange={(e) => setValues((prev) =>
                    ({ ...prev, DestinoCod: e.target.value }))} />
                <BuscarPorto porto={desembarque} />
              </InputGroup>
              <Form.Text>{Values.Desembarque}</Form.Text>
            </Form.Group>
            <Form.Group as={Col} md={12}>
              <Form.Label>Descrição</Form.Label>
              <Form.Control as="textarea" rows={3} value={Values.descricao} />
            </Form.Group>


          </Row>
        </Form>
        <Table responsive hover striped size='sm'>
          <thead>
            <tr>
              <th>Item</th>
              <th>Descrição</th>
              <th>Ncm</th>
              <th>Peso</th>
            </tr>
          </thead>
          <tbody>
            {Values !== "indefined" && (Values.items.length > 0) && Values.items.map((item, index) => {
              const org = orgItem(item, Values.descricao)
              return (
                <>
                  <tr className={org.cor}>
                    <th>{parseInt(item.item)}</th>
                    <td>{org.descricao}</td>
                    <td title={org.ncmdescr} className={item.ncms[0] ?? 'table-danger'}>{org.ncm}</td>
                    <td>{formatarValorQuilograma(parseFloat(item.Pbruto))}</td>
                    <td><Button size='sm' onClick={() => selecionar({ ...item })}><i className='bi bi-eye' /></Button></td>
                  </tr>
                </>
              )
            })}
          </tbody>
        </Table>
        <ButtonGroup className="mb-2">
          <Button variant="secondary" onClick={() => { navigate(-1); }}>
            Cancelar
          </Button>
          <Button variant={botao.tipo} type="submit" form="formulario">
            {botao.titulo}
          </Button>
        </ButtonGroup>
      </Card.Body>
    </Card >
  );
}
