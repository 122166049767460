import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

export default function FormItem({ item, onSave }) {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)
  const [Values, setValues] = useState({
    cntNum: '',
    cntCap: '',
    cntcTp: '',
    cnttip: '',
    cnttar: '',
    cntpar: '',
    cntLac: '',
    cargaSoltaqtd: '',
    cargaSoltaCodTpEmb: '',
    cargaSoltaTpEmb: '',
    grcod: '',
    grtp: '',
    VeicChassi: '',
    VeicMarca: '',
    contraMarca: '',
    Pbruto: '',
    m3: '',
    ncms: []
  })
  useEffect(() => {
    handleClose();
    if (item.Pbruto) {
      setValues(item);
      handleShow();
    }
  }, [item])
  const tipo = [{ id: 1, descr: 'Conteiner' }, { id: 2, descr: 'Carga solta' }, { id: 3, descr: 'Granel' }, { id: 4, descr: 'Veiculo' }];
  const Salvar = () => {
    onSave(Values)
    handleClose();
  }
  return (
    <>
      <Button size='sm' onClick={() => handleShow()}>
        Novo
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          Item de Lote
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Tipo</Form.Label>
                <Form.Select size="sm" value={Values.codtp} onChange={(a) => { setValues((prevState) => ({ ...prevState, codtp: a.target.value })); }}>
                  <option>Selecione</option>
                  {tipo.map((t) => (<option selected={Values.codtp === t.id} key={t.id} value={t.id}>{t.descr}</option>))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row hidden={Values.codtp !== '1'}>
              <Col md={4}><Form.Group className="mb-3"><Form.Label>Numero</Form.Label><Form.Control size="sm" value={Values.cntNum} placeholder="Numero do Conteiner (A11)" onChange={(e) => setValues((prev) => ({ ...prev, cntNum: e.target.value }))} /></Form.Group></Col>
              <Col md={2}><Form.Group className="mb-3"><Form.Label>Capacidade</Form.Label><Form.Control size="sm" value={Values.cntCap} placeholder="CAPACIDADE DO CONTEINER EM PÉS(A02)" onChange={(e) => setValues((prev) => ({ ...prev, cntCap: e.target.value }))} /></Form.Group></Col>
              <Col md={2}><Form.Group className="mb-3"><Form.Label>Codigo Tipo</Form.Label><Form.Control size="sm" value={Values.cntcTp} placeholder="CÓDIGO DO TIPO DE CONTEINER (A04)" onChange={(e) => setValues((prev) => ({ ...prev, cntcTp: e.target.value }))} /></Form.Group></Col>
              <Col md={4}><Form.Group className="mb-3"><Form.Label>Container Tipo</Form.Label><Form.Control size="sm" value={Values.cnttip} placeholder="TIPO DO CONTEINER (A60)" onChange={(e) => setValues((prev) => ({ ...prev, cnttip: e.target.value }))} /></Form.Group></Col>
              <Col md={4}><Form.Group className="mb-3"><Form.Label>Tara</Form.Label><Form.Control size="sm" value={Values.cnttar} placeholder="TARA DO CONTEINER EM KILOS (N6,3)" onChange={(e) => setValues((prev) => ({ ...prev, cnttar: e.target.value }))} /></Form.Group></Col>
              <Col md={2}><Form.Group className="mb-3"><Form.Label>indicador</Form.Label><Form.Control size="sm" value={Values.cntpar} placeholder="INDICADOR DE USO PARCIAL DO CONTEINER (A01) | S ou N" onChange={(e) => setValues((prev) => ({ ...prev, cntpar: e.target.value }))} /></Form.Group></Col>
              <Col md={6}><Form.Group className="mb-3"><Form.Label>Lacres</Form.Label><Form.Control size="sm" value={Values.cntLac} placeholder="NÚMERO DO LACRE DO CONTEINER (A15) 4x" onChange={(e) => setValues((prev) => ({ ...prev, cntLac: e.target.value }))} /></Form.Group></Col>
            </Row>
            <Row hidden={Values.codtp !== '2'}>
              <Col><Form.Group className="mb-3"><Form.Label>Quantidade de itens</Form.Label><Form.Control size="sm" value={Values.cargaSoltaqtd} placeholder="QUANTIDADE DE ÍTENS DA CARGA SOLTA (N07)" onChange={(e) => setValues((prev) => ({ ...prev, cargaSoltaqtd: e.target.value }))} /></Form.Group></Col>
              <Col><Form.Group className="mb-3"><Form.Label>Select emb</Form.Label><Form.Control size="sm" value={Values.cargaSoltaCodTpEmb} placeholder="CÓDIGO DO TIPO DE EMBALAGEM DA CARGA SOLTA (A02)" onChange={(e) => setValues((prev) => ({ ...prev, cargaSoltaCodTpEmb: e.target.value }))} /></Form.Group></Col>
              <Col><Form.Group className="mb-3"><Form.Label>Tipo embalagem</Form.Label><Form.Control size="sm" value={Values.cargaSoltaTpEmb} placeholder="TIPO DE EMBALAGEM DA CARGA SOLTA (A60)" onChange={(e) => setValues((prev) => ({ ...prev, cargaSoltaTpEmb: e.target.value }))} /></Form.Group></Col>
            </Row>
            <Row hidden={Values.codtp !== '3'}>
              <Col><Form.Group className="mb-3"><Form.Label>select</Form.Label><Form.Control size="sm" value={Values.grcod} placeholder="CÓDIGO DO TIPO DE GRANEL (A02) " onChange={(e) => setValues((prev) => ({ ...prev, grcod: e.target.value }))} /></Form.Group></Col>
              <Col><Form.Group className="mb-3"><Form.Label>Tipo Granel</Form.Label><Form.Control size="sm" value={Values.grtp} placeholder="TIPO DO GRANEL (A60)" onChange={(e) => setValues((prev) => ({ ...prev, grtp: e.target.value }))} /></Form.Group></Col>
            </Row>
            <Row hidden={Values.codtp !== '4'}>
              <Col><Form.Group className="mb-3"><Form.Label>Chassi</Form.Label><Form.Control size="sm" value={Values.VeicChassi} placeholder="NÚMERO DO CHASSI DO VEÍCULO (A30)" onChange={(e) => setValues((prev) => ({ ...prev, VeicChassi: e.target.value }))} /></Form.Group></Col>
              <Col><Form.Group className="mb-3"><Form.Label>Marca</Form.Label><Form.Control size="sm" value={Values.VeicMarca} placeholder="MARCA DO VEÍCULO (A55)" onChange={(e) => setValues((prev) => ({ ...prev, VeicMarca: e.target.value }))} /></Form.Group></Col>
              <Col><Form.Group className="mb-3"><Form.Label>Modelo</Form.Label><Form.Control size="sm" value={Values.contraMarca} placeholder="CONTRAMARCA (A55)" onChange={(e) => setValues((prev) => ({ ...prev, contraMarca: e.target.value }))} /></Form.Group></Col>
            </Row>
            <Row>
              <Col md={3}><Form.Group className="mb-3"><Form.Label>Peso Bruto</Form.Label><Form.Control size="sm" value={Values.Pbruto} placeholder="PESO BRUTO DO ÍTEM EM KILOS (N9,3)" onChange={(e) => setValues((prev) => ({ ...prev, Pbruto: e.target.value }))} /></Form.Group></Col>
              <Col><Form.Group className="mb-3"><Form.Label>Cubagem</Form.Label><Form.Control size="sm" value={Values.m3} placeholder="CUBAGEM DO  ÍTEM EM METROS CÚBICOS (N10,3)" onChange={(e) => setValues((prev) => ({ ...prev, m3: e.target.value }))} /></Form.Group></Col>
            </Row>
            <Row>
              <Col md={2}><Form.Group className="mb-3"><Form.Label>NCM</Form.Label><Form.Control size="sm" value={Values.ncms[0] ? Values.ncms[0].codmerc : ''} placeholder="PESO BRUTO DO ÍTEM EM KILOS (N9,3)" onChange={(e) => setValues((prev) => ({ ...prev, Pbruto: e.target.value }))} /></Form.Group></Col>
              <Col><Form.Group className="mb-3"><Form.Label>Descriçao</Form.Label><Form.Control size="sm" readOnly value={Values.ncms[0] ? Values.ncms[0].descr : ''} placeholder="CUBAGEM DO  ÍTEM EM METROS CÚBICOS (N10,3)" onChange={(e) => setValues((prev) => ({ ...prev, m3: e.target.value }))} /></Form.Group></Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Fechar</Button>
          <Button onclick={Salvar}>Salvar</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}