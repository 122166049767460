import { child, get, getDatabase, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react';
import {
  Card, Row, Col,
  ListGroup,
  ListGroupItem,
  Button,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { formatarValorQuilograma } from '../Components/formatos';

export default function ListaOpe() {
  const [Arquivos, setArquivos] = useState([]);
  const navigate = useNavigate();
  function getSolicitacaoCargaArquivo() {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `cop/Escala/`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const values = snapshot.val();
          const valuesArr = [];
          for (const id in values) {
            values[id].id = id;
            valuesArr.push(values[id]);
          }
          const ordenar = (a, b) => {
            return b.dat_emissao - a.dat_emissao
          }
          setArquivos(valuesArr.sort(ordenar));
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }
  useEffect(() => {
    getSolicitacaoCargaArquivo()
  }, [])
  return (
    <Card>
      <Card.Header as="h3">
        <Row>
          <Col sm={11}>
            Lista de operações
          </Col>
          <Col>
            <Button onClick={() => navigate('/Listaope/cadastro')} size='sm'>Novo</Button>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row xs={1} sm={2} md={3} lg={4} xl={4} xxl={6} className="g-4">
          {typeof Arquivos !== "undefined" && Arquivos.map((ope, index) => (
            <Col key={index}>
              <Card className='operacoes' onClick={() => navigate(`/operacao/${ope.Escala}`)}>
                <Card.Header>Mv.{ope.navio} V.{ope.viagem}</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <ListGroup>
                      <ListGroupItem>Escala.: {ope.Escala} </ListGroupItem>
                      <ListGroupItem>Man.: {formatarValorQuilograma(ope.peso)} </ListGroupItem>
                    </ListGroup>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
}
