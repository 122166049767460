import React, { useState } from 'react';
import {
  Button, Form, Alert, Card,
} from 'react-bootstrap';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import CheckPassword from '../Components/Loading/progressPass';
import RecuperaUsuario from '../01_Usuario/User_redefinir';
import config from '../Components/config/config';

function Login() {
  const [show, setShow] = useState(false);
  const [Valid, setValid] = useState(false);
  // campos de usuario
  const [User, setUser] = useState('');
  const [Password, setPassword] = useState('');
  const navigate = useNavigate();
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    const Authorization = config.Key;
    await fetch(`${config.urlRootNode}Usuarios/Logar`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
      body: JSON.stringify({ Usuario: User, Senha: Password }),
    }).then(async response => {
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error);
      }
      return response.json();
    }).then(data => {
      localStorage.setItem('usuario', JSON.stringify(data))
      navigate('/');
    })
      .catch(error => { console.log(error); setShow(true); });
  };


  const ativar = (childdata) => {
    setValid(childdata);
  };
  return (
    <>
      <Card.Body>
        <Form id="login" onSubmit={onSubmitHandler}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Usuário</Form.Label>
            <Form.Control required type="text" name="usuario" placeholder="Entre com seu usuário" onChange={(e) => setUser(e.target.value)} />
            <Form.Text className="text-muted">Para sua segurança, não compartilhe seu usuario e senha.                        </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Senha</Form.Label>
            <Form.Control required type="password" placeholder="Senha" onChange={(e) => setPassword(e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Text className="text-muted" />
          </Form.Group>
          <Alert show={show} variant="danger">
            <Alert.Heading>Acesso Negado</Alert.Heading>
            <p>Seu acesso foi negado. Solcite seu acesso ou tente novamente</p>
            <hr />
            <p>
              Caso tenha esquecido sua senha,
              <RouterLink to="Redefinir">Clique aqui</RouterLink>
            </p>
            <div className="d-flex justify-content-end">
              <Button onClick={() => setShow(false)} variant="outline-danger">
                Fechar
              </Button>
            </div>
          </Alert>
        </Form>
        <CheckPassword password={Password} ativa={ativar} hidden />
      </Card.Body>
      <Card.Footer className="d-flex justify-content-between">
        <RecuperaUsuario />
        <Button variant="primary" form="login" disabled={!Valid} type="submit">
          Entrar
        </Button>
      </Card.Footer>
    </>
  );
}
export default Login;
