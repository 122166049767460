import React from 'react';
import {
  Container, Dropdown, Navbar,
} from 'react-bootstrap';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import './estilo.css';
import LogoCentro from '../../images/LogoCentro';

function Header({ User }) {
  const navigate = useNavigate();
  function sair() {
    localStorage.clear();
    navigate('/Acesso');
  }
  return (
    <Navbar bg="light" sticky="top" className="header">
      <Container fluid>
        <LogoCentro />
        <Dropdown drop="start">
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {User.nome}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item hidden as={RouterLink} to={`Usuario/${User.id}`}>Meu perfil</Dropdown.Item>
            <Dropdown.Item onClick={() => sair()}>Sair</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Container>
    </Navbar>
  );
}
export default Header;
