import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';

export default function Dashboard() {
  return (
    <>
      <Card.Header as="h3">Graficos Gerais</Card.Header>
      <Card.Body>
        <Row>
          <Col md={8}>
          </Col>
          <Col />
        </Row>
      </Card.Body>
    </>
  );
}
