import React from 'react';
import { ProgressBar } from 'react-bootstrap';

export default function CheckPassword({ password = '', ativa = false, hidden = false }) {
  let strength = 0;
  const regex = /[$-/:-?{-~!@#"^_`\\[\]]/g; // caracteres especiais
  // adicionar pontos para cada critério de senha atendido
  if (password.length > 7) {
    strength += 1;
  }
  if (password.match(/[a-z]+/)) {
    strength += 1;
  }
  if (password.match(/[A-Z]+/)) {
    strength += 1;
  }
  if (password.match(/\d+/)) {
    strength += 1;
  }
  if (password.match(regex)) {
    strength += 1;
  }
  // calcular a porcentagem de força da senha
  const percentage = (strength / 5) * 100;

  if (percentage === 100) {
    ativa(true);
  } else {
    ativa(false);
  }
  return (
    <ProgressBar hidden={hidden}>
      <ProgressBar striped variant="danger" now={percentage >= 20 ? 20 : 0} key={1} />
      <ProgressBar striped variant="secondary" now={percentage >= 40 ? 20 : 0} key={2} />
      <ProgressBar striped variant="warning" now={percentage >= 60 ? 20 : 0} key={3} />
      <ProgressBar striped variant="primary" now={percentage >= 80 ? 20 : 0} key={4} />
      <ProgressBar striped variant="success" now={percentage >= 100 ? 20 : 0} key={5} />
    </ProgressBar>
  );
}
