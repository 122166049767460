/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  InputGroup,
  ButtonGroup,
} from 'react-bootstrap';
import { datetimeLocal, days } from '../../Components/formatos';



export default function AdParalizacao({ atr }) {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const hoje = new Date();
  const [Values, setValues] = useState({
    inicio: datetimeLocal(hoje),
    fim: datetimeLocal(hoje),
    duracao: ''
  })
  useEffect(() => {
    setValues((prev) => ({ ...prev, duracao: days(new Date(Values.inicio), new Date(Values.fim)).text }))
  }, [Values.inicio, Values.fim])
  return (
    <>
      <Button size='sm' onClick={handleShow}>Novo</Button>
      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Paralização</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row md={2}>
              <Col className='mb-3'>
                <Form.Group>
                  <Form.Label>Paralização</Form.Label>
                  <Form.Control size='sm' />
                </Form.Group>
              </Col>
              <Col className='mb-3'>
                <Form.Group>
                  <Form.Label>Observação</Form.Label>
                  <Form.Control size='sm' />
                </Form.Group>
              </Col>
              <Col className='mb-3'>
                <Form.Group>
                  <Form.Label>Inicio - Fim</Form.Label>
                  <InputGroup size='sm'>
                    <Form.Control value={datetimeLocal(Values.inicio)} onChange={(e) => setValues((prev) => ({ ...prev, inicio: e.target.value }))} type='datetime-local' />
                    <Form.Control value={datetimeLocal(Values.fim)} onChange={(e) => setValues((prev) => ({ ...prev, fim: e.target.value }))} type='datetime-local' />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col className='mb-3'>
                <Form.Group>
                  <Form.Label>Duração</Form.Label>
                  <Form.Control value={Values.duracao} readOnly />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup size='sm'>
            <Button variant='secondary' onClick={handleClose}>Cancelar</Button>
            <Button variant='success' onClick={handleClose}>Enviar</Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    </>
  );
}
