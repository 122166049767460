import React, { useEffect, useState } from 'react';
import {
  Card, Col, ListGroup, Row,
} from 'react-bootstrap';
import Siscarga from './5.0_Componentes/Siscarga';
import { child, get, getDatabase, ref } from 'firebase/database';
import { formatarValorQuilograma } from '../Components/formatos';
import { dataEdi } from './5.0_Componentes/Siscarga/separador';
/* 
// Operação de exportação
Cadastro do Booking -> Registrar Notas fiscais de entrada -> Cadastrar BL selecionando os blocos do booking
Cadastro da DUE selecionando os blocos do booking

//Cadastro de navio
Cadastrar um veículo novo e selecionar tipo maritmo

//Cadastro de operação
Nova operação -> [Carga | Descarga | Carga e descarga]
Selecionar Documentos [BL -> Descarga] [Due -> Carga]
*/
export default function Integracao() {
  const [arquivos, setArquivos] = useState([]);
  const [Sel, setSel] = useState();
  function getSolicitacaoCargaArquivo() {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `cop/edi/`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const values = snapshot.val();
          const valuesArr = [];
          for (const id in values) {
            values[id].id = id;
            valuesArr.push(values[id]);
          }
          const ordenar = (a, b) => {
            return b.dat_emissao - a.dat_emissao
          }
          setArquivos(valuesArr.sort(ordenar));
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }
  useEffect(() => {
    getSolicitacaoCargaArquivo()
  }, [])
  return (
    <>
      <Card>
        <Card.Header as="h3">
          <Row>
            <Col sm={10}>
              <Siscarga dados={Sel} />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row md={4}>
            {typeof arquivos !== "undefined" && arquivos.map((edi, index) => {
              const date = new Date(edi.dat_emissao);
              // Obter os componentes da data
              const day = String(date.getDate()).padStart(2, '0');
              const month = String(date.getMonth() + 1).padStart(2, '0'); // Os meses em JavaScript são baseados em zero
              const year = String(date.getFullYear()).slice(-2);
              const hours = String(date.getHours()).padStart(2, '0');
              const minutes = String(date.getMinutes()).padStart(2, '0');
              const seconds = String(date.getSeconds()).padStart(2, '0');
              // Formatar a data no formato desejado
              const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
              return (
                <Col key={index}>
                  <Card className='operacoes' onClick={() => {
                    setSel()
                    setTimeout(() => {
                      setSel(edi);
                    }, 100)
                  }}>
                    <Card.Header>MV: {edi.Escala[0].navio} V.{edi.Escala[0].viagem}</Card.Header>
                    <Card.Body>
                      <ListGroup>
                        <ListGroup.Item>Previsão: {dataEdi(edi.Escala[0].previsao)}</ListGroup.Item>
                        <ListGroup.Item>Escala: {edi.numero}</ListGroup.Item>
                        <ListGroup.Item>Peso: {formatarValorQuilograma(edi.Escala[0].peso)}</ListGroup.Item>
                        <ListGroup.Item>Imo: {edi.Escala[0].imo}</ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                    <Card.Footer>
                      <small className="text-muted">
                        Mod.: {formattedDate}
                      </small>
                    </Card.Footer>
                  </Card>
                </Col>
              )
            })}
          </Row>
        </Card.Body>
      </Card >
    </>
  );
}
