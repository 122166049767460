export function dataEdi(data) {
  if (data === 'undefined') { return 0; }
  if (data === undefined) { return 0; }
  const dia = data.substr(6, 2);
  const mes = data.substr(4, 2);
  const ano = data.substr(0, 4);
  return `${dia}/${mes}/${ano}`;
}
//serão removidos todos
export function escala(registro) {
  /*
    TIPO 1 [367 bytes]- DADOS DA ESCALA - UM REGISTRO PARA CADA ESCALA.
    TIPO DO REGISTRO n01
    Grupo Bloqueio da Escala 4x
   7 - CÓDIGO DO TIPO DE BLOQUEIO DA  ESCALA a02
   
   10- DATA DO DEADLINE PARA SOLICITAR A DTSC n08
   11- HORA DO DEADLINE PARA SOLICITAR A DTSC n06
    */
  return {
    numero: registro.substr(1, 11).trim(),   // 1 - NÚMERO DA ESCALA n11
    imo: registro.substr(12, 10).trim(),     // 2 - CÓDIGO IMO DA EMBARCAÇÃO a10
    navio: registro.substr(22, 55).trim(),   // 3 - NOME DA EMBARCACAO a55
    previsao: registro.substr(77, 8).trim(), // 4 - DATA PREVISTA DA PRIMEIRA ATRACAÇÃO  n08
    atracado: registro.substr(85, 8).trim(), // 5 - DATA EFETIVA DA PRIMEIRA ATRACAÇÃO n08
    bloqueada: registro.substr(84, 1).trim(),// 6 - INDICADOR DE ESCALA BLOQUEADA a01
    bloqDescr: registro.substr(87, 60).trim(), //8 - DESCRIÇÃO DO BLOQUEIO DA ESCALA a60
    viagem: registro.substr(342, 10).trim(), // 9 - NÚMERO DA VIAGEM  a10
  }
}

export function manifesto(registro) {
  /*
TIPO 2 [615 bytes]- DADOS DO MANIFESTO - UM REGISTRO PARA CADA MANIFESTO DA  ESCALA.  
1 - TIPO DO REGISTRO (N01)
8 - INDICADOR DE MANIFESTO BLOQUEADO (A01)
Grupo Bloqueio do manifestoComposto pelos dois campos a seguir
9 - CÓDIGO DO TIPO DE BLOQUEIO DO MANIFESTO (A02)
10- DESCRIÇÃO DO BLOQUEIO DO MANIFESTO (A60)
 */
  return {
    escala: registro.substr(1, 11).trim(),        //2 - NÚMERO DA ESCALA (N11)
    manifesto: registro.substr(12, 13).trim(),    //3 - NÚMERO DO MANIFESTO (N13)
    portoeCod: registro.substr(25, 5).trim(),     //4 - CÓDIGO DO PORTO DE CARREGAMENTO (A05)
    PortoeNome: registro.substr(30, 60).trim(),   //5 - NOME DO PORTO DE CARREGAMENTO (A60)
    portodCod: registro.substr(90, 5).trim(),     //6 - CÓDIGO DO PORTO DE DESCARREGAMENTO (A05)
    PortodNome: registro.substr(95, 60).trim(),   //7 - NOME DO PORTO DE DESCARREGAMENTO (A60)
    AgenciaDoc: registro.substr(404, 14).trim(), //11- CNPJ  DA  AGÊNCIA DE NAVEGAÇÃO RESPONSÁVEL PELO MANIFESTO (N14)
    AgenciaNome: registro.substr(418, 60).trim(), //12- NOME  DA  AGÊNCIA DE NAVEGAÇÃO RESPONSÁVEL PELO MANIFESTO (A60)
    ArmadorDoc: registro.substr(478, 14).trim(),  //13- CÓDIGO DA EMPRESA  DE NAVEGAÇÃO RESPONSÁVEL PELO MANIFESTO (A14)
    ArmadorNome: registro.substr(492, 60).trim(), //14- NOME  DA  EMPRESA DE NAVEGAÇÃO RESPONSÁVEL PELO MANIFESTO (A60)
    TrafegoCod: registro.substr(552, 2).trim(),   //15- CÓDIGO DO TIPO DE TRAFEGO (A02)
    TrafegoNome: registro.substr(554, 60).trim()  //16- NOME DO TIPO DE TRAFEGO (A60)
  }
}

export function CeMercante(registro) {
  /* TIPO 4[3344 bytes]- DADOS CE MERCANTE - UM REGISTRO POR CE MERCANTE. */
  return {
    CeNumero: registro.substr(1, 15).trim(),            //1 - NÚMERO DO CE MERCANTE (N15)
    manifesto: registro.substr(16, 130).trim(),         //2 - NÚMERO DO MANIFESTO (N13) Ocorre 10 vezes. Número dos manifestos nos quais este CE foi incluído ou associado para baldeação. As ocorrências vazias estarão em branco.
    codtipo: registro.substr(146, 2).trim(),            //3 - CÓDIGO DO TIPO DE CONHECIMENTO (N02) ‘10’,’11’,’12’,’15’
    tipoConhec: registro.substr(148, 4).trim(),         //4 - TIPO DO CONHECIMENTO (A04) ‘BL’,’MBL’,’HBL’,’MHBL’ – de acordo com o código anterior
    codCatCarg: registro.substr(152, 1).trim(),         //5 - CÓDIGO DA CATEGORIA DA CARGA (A01) ‘I’,’E’,’N’,’P’
    CatCarga: registro.substr(153, 25).trim(),          //6 - CATEGORIA DA CARGA (A25) ‘importada’, ’exportada’, ’nacional’, ’estrangeira de  passagem’ – de acordo com o código anterior
    CodSitu: registro.substr(178, 2).trim(),            //7 - CÓDIGO DA SITUAÇÃO  DA CARGA (N02) ‘01’,’03’,’04’,’05’,’06’,’07’
    SituCarga: registro.substr(180, 35).trim(),         //8 - SITUACAO DA CARGA (A35) ‘manifestada’ , ‘descarregada’, ’carregada’, ’armazenada’, ’vinculada a documento de despacho ou processo’, ‘entregue’ – de acordo com o código anterior
    dataSit: registro.substr(215, 8).trim(),            //9 - DATA DA SITUAÇÃO DA CARGA (N08) formato aaaammdd
    indicaDisp: registro.substr(223, 1).trim(),         //10- INDICADOR DE DISPONIBILIDADE PARA ENTREGA (A01) ‘S’ ou  ‘N‘
    BLOrig: registro.substr(224, 18).trim(),            //11- NÚMERO ORIGINAL DO BL (A18)
    CEMaster: registro.substr(246, 15).trim(),          //12- NÚMERO DO CE  MASTER (N15) preenchido somente quando a carga for House(HBL) ou   Coloader (MHBL)
    AgenteDescDoc: registro.substr(262, 14).trim(),     //13- CNPJ DO AGENTE DESCONSOLIDADOR (N14)
    AgenteDesc: registro.substr(277, 115).trim(),       //14- NOME DO AGENTE DESCONSOLIDADOR (A115)
    docEmpNav: registro.substr(393, 14).trim(),         //15- CNPJ DA EMPRESA DE NAVEGAÇÃO DESCONSOLIDADORA (N14)
    EmpNav: registro.substr(409, 115).trim(),           //16- NOME DA EMPRESA DE NAVEGAÇÃO DESCONSOLIDADORA (A115)
    cpf_PesNotificada: registro.substr(515, 14).trim(), //17- NÚMERO DO CPF / CNPJ DA PARTE  NOTIFICADA (A14)
    PesNotificada: registro.substr(529, 115).trim(),    //18- NOME DA PARTE NOTIFICADA (A115)
    Mensagem: registro.substr(644, 253).trim(),         //19- TEXTO IDENTIFICAÇÃO DA PARTE NOTIFICADA // confuso ainda (A253)
    ConsgDoc: registro.substr(897, 14).trim(),          //20- CPF/CNPJ DO CONSIGNATÁRIO DA CARGA (A14)
    ConsgNome: registro.substr(911, 115).trim(),        //21- NOME DO CONSIGNATÁRIO DA CARGA (A115)
    ConsExtr: registro.substr(1026, 1).trim(),          //22- INDICADOR DE CONSIGNATÁRIO ESTRANGEIRO (A01) ‘S’ OU ‘N’

    23: registro.substr(1027, 30).trim(),               //23- NÚMERO DO PASSAPORTE DO CONSIGNATÁRIO ESTRANGEIRO(A30)
    24: registro.substr(1057, 55).trim(),               //24- NOME DO CONSIGNATÁRIO ESTRANGEIRO(A55)
    "25-Consigtxt": registro.substr(1112, 253).trim(),  //25- TEXTO IDENTIFICADOR DO CONSIGNATÁRIO(A253)
    26: registro.substr(1365, 14).trim(),               //26- NÚMERO DO CPF/ CNPJ DO EMBARCADOR(A14)
    27: registro.substr(1379, 115).trim(),              //27- NOME DO EMBARCADOR(A115)

    Embarcador: registro.substr(1494, 253).trim(),      //28- TEXTO IDENTIFICADOR DO EMBARCADOR(A253)
    CeEmissao: registro.substr(1747, 8).trim(),         //29- DATA DE EMISSÃO DO CE(N08) formato aaaammdd
    descricao: registro.substr(1755, 506).trim(),       //30- TEXTO DESCRIÇÃO DA MERCADORIA(A506)
    OrigemCod: registro.substr(2261, 5).trim(),         //31- CÓDIGO DO PORTO ORIGEM DA CARGA(A05)
    PortoEmbarque: registro.substr(2266, 60).trim(),    //32- NOME DO PORTO ORIGEM DA CARGA(A60)
    DestinoCod: registro.substr(2326, 5).trim(),  //33- CÓDIGO DO PORTO DESTINO FINAL DA CARGA(A05)
    Desembarque: registro.substr(2331, 60).trim(),      //34- NOME DO PORTO DESTINO FINAL DA CARGA(A60)
    AgenciaEmissDoc: registro.substr(2391, 14).trim(),  //35- CNPJ DA AGÊNCIA EMISSORA DO CE(N14)
    AgenciaEmissora: registro.substr(2405, 115).trim(), //36- NOME DA AGÊNCIA EMISSORA DO CE(A115)

    37: registro.substr(2520, 15).trim(),               //37- NÚMERO DO ÚLTIMO DOCUMENTO DE DESPACHO DA CARGA(A15)
    38: registro.substr(2535, 6).trim(),                //38- CÓDIGO DO TIPO DE DOCUMENTO DE DESPACHO(A06)  ‘DI’ => Declaração de Importação, DI- OEA => Declaração de Importação de Importador cadastrado como OEA(Operador Economicamente Autorizado), ’DSI’ => Declaração simplificada de Importação, ’DTA’ => Declaração de Trânsito Aduaneiro, ‘DTSC’ => Declaração de Transferência Simplificada de Carga,‘DUIMP’ => Declaração Única de Importação
    39: registro.substr(2541, 1).trim(),                //39- INDICADOR DE CARGA BLOQUEADA(A01)  S ou N
    /* Grupo Bloqueio da Carga Composto pelos dois campos abaixo  Ocorre quatro vezes, podendo estar em branco. */
    BloqCod: registro.substr(2542, 2).trim(),           //40- CÓDIGO DO TIPO DEBLOQUEIO DA CARGA(A02)
    BloqDesc: registro.substr(2544, 60).trim(),         //41- DESCRIÇÃO DO TIPO DE BLOQUEIO DA CARGA(A60)


    42: registro.substr(2848, 1).trim(),                //42- INDICADOR DE PRAÇA DE ENTREGA NO EXTERIOR(A01)    ‘S’ ou  ‘N’
    "43-CodPais": registro.substr(2849, 2).trim(),       //43- CÓDIGO DO PAÍS DE ENTREGA DA CARGA(A02)
    44: registro.substr(2851, 55).trim(),               //44- NOME DO PAÍS DE ENTREGA DA CARGA(A55)
    45: registro.substr(2906, 1).trim(),                //45- INDICADOR DE BL DE  SERVIÇO(A01)  ‘S’ ou  ‘N’
    46: registro.substr(2922, 15).trim(),               //46- NÚMERO DO CE MERCANTE ORIGINAL DO BL DE SERVICO(N15)

    PesoBruto: registro.substr(2864, 12).trim(),        //47- PESO BRUTO DA CARGA EM KILOS(N9,3)
    Cubagem: registro.substr(2877, 13).trim(),          //48- CUBAGEM DA CARGA EM METROS CÚBICOS(N10,3)
    Observacao: registro.substr(2890, 253).trim(),      //49- TEXTO COM OBSERVAÇÕES(A253)

    50: registro.substr(3143, 110).trim(),              //50- NÚMERO DO CONTAINER SHIP’S CONVENIENCE(A11) Ocorre dez vezes, podendo estar em branco. --------- atenção nesse ponto
    51: registro.substr(3352, 2).trim(),                //51- CÓDIGO DA MODALIDADE DO FRETE(A02)
    52: registro.substr(3255, 15).trim(),               //52- NOME DA MODALIDADE DO FRETE(A15)
    53: registro.substr(3270, 7).trim(),                //53- CÓDIGO DO RECINTO ADUANEIRO RESPONSÁVEL PELA DTSC(A07) Atualmente em branco. A ser implementado
    54: registro.substr(3276, 60).trim(),               //54- NOME  DO RECINTO ADUANEIRO RESPONSÁVEL PELA DTSC(A60) Atualmente em branco. A ser implementado
    55: registro.substr(3336, 7).trim(),                //55- CÓDIGO DA UNIDADE LOCAL  DE DESTINO FINAL DA CARGA(N07)
  }
}

export function itemCarga(registro) {
  const codTipo = ['Conteiner', 'Carga solta', 'Granel', 'Veiculo'];
  return {
    cemercante: registro.substr(1, 15).trim(),          //1 - NÚMERO DO CE MERCANTE (A15)
    item: registro.substr(16, 4).trim(),                //2 - NÚMERO DO ÍTEM DE CARGA (A04)
    codtp: registro.substr(20, 1).trim(),               //3 - CÓDIGO DO TIPO DE ÍTEM (A01) ‘1’ = conteiner, ‘2’ = carga solta,‘3’ = granel,‘4’  = veículo

    tipo: codTipo[parseInt(registro.substr(20, 1).trim()) - 1],

    cntNum: registro.substr(21, 11).trim(),             //4 - NÚMERO DO CONTEINER (A11)
    cntCap: registro.substr(32, 2).trim(),              //5 - CAPACIDADE DO CONTEINER EM PÉS(A02)
    cntcTp: registro.substr(34, 4).trim(),              //6 - CÓDIGO DO TIPO DE CONTEINER (A04)
    cnttip: registro.substr(38, 60).trim(),             //7 - TIPO DO CONTEINER (A60)
    cnttar: registro.substr(98, 9).trim(),              //8 - TARA DO CONTEINER EM KILOS (N6,3)
    cntpar: registro.substr(107, 1).trim(),             //9 - INDICADOR DE USO PARCIAL DO CONTEINER (A01) | S ou N
    cntLac: registro.substr(108, 60).trim(),            //10- NÚMERO DO LACRE DO CONTEINER (A15) 4x

    grcod: registro.substr(166, 4).trim(),              //11- CÓDIGO DO TIPO DE GRANEL (A02) 
    grtp: registro.substr(170, 60).trim(),              //12- TIPO DO GRANEL (A60)

    cargaSoltaqtd: registro.substr(230, 7).trim(),      //13- QUANTIDADE DE ÍTENS DA CARGA SOLTA (N07)
    cargaSoltaCodTpEmb: registro.substr(237, 2).trim(), //14- CÓDIGO DO TIPO DE EMBALAGEM DA CARGA SOLTA (A02)
    cargaSoltaTpEmb: registro.substr(239, 60).trim(),   //15- TIPO DE EMBALAGEM DA CARGA SOLTA (A60)

    VeicChassi: registro.substr(299, 30).trim(),        //16- NÚMERO DO CHASSI DO VEÍCULO (A30)
    VeicMarca: registro.substr(329, 55).trim(),         //17- MARCA DO VEÍCULO (A55)
    contraMarca: registro.substr(384, 55).trim(),       //18- CONTRAMARCA (A55)

    Pbruto: parseFloat((registro.substr(439, 12).trim()) / 1000).toFixed(3), //19- PESO BRUTO DO ÍTEM EM KILOS (N9,3)
    m3: registro.substr(451, 13).trim(),                //20- CUBAGEM DO  ÍTEM EM METROS CÚBICOS (N10,3)

    perigoCod: registro.substr(464, 6).trim(),          //21- CÓDIGO DE MERCADORIA PERIGOSA (A06)
    perigoClasse: registro.substr(470, 4).trim(),       //22- CLASSE DE  MERCADORIA PERIGOSA (A04)

    Bloq: registro.substr(474, 1).trim(),               //23- INDICADOR DE ÍTEM  BLOQUEADO (A01)
    BlocCod: registro.substr(475, 2).trim(),            //24- CÓDIGO DO TIPO DE BLOQUEIO DO ITEM DE  CARGA(A02)
    BlocDesc: registro.substr(477, 60).trim(),          //25- DESCRIÇÃO DO TIPO DE BLOQUEIO DO ITEM  DE CARGA (A60)
  }
}

export function ncmCarga(registro) {
  return {
    cemercante: registro.substr(1, 15).trim(),          //1 - NÚMERO DO CE MERCANTE (N15)
    item: registro.substr(16, 4).trim(),                //2 - NÚMERO DO ÍTEM DE CARGA (N04)
    codmerc: registro.substr(20, 8).trim(),             //3 - CÓDIGO DA MERCADORIA (A08)
    descr: registro.substr(28, 140).trim()              //4 - DESCRIÇÃO DA MERCADORIA (A140)
  }
}

//será mantido por ser organização para front-ent
export function orgItem(item, descricaoCE) {
  let descricao = '';
  let cor = '';
  let ncm = item.ncms[0] && item.ncms[0].codmerc ? `${item.ncms[0].codmerc}` : '';
  let ncmdescr = item.ncms[0] && item.ncms[0].codmerc ? `${item.ncms[0].codmerc} - ${item.ncms[0].descr}` : '';
  switch (item.codtp) {
    case '1':
      descricao = ''
      break;
    case '2':
      /* carga solta */
      descricao = `${item.tipo} | ${parseFloat(item.cargaSoltaqtd > 0 ? item.cargaSoltaqtd : '0')} ${item.cargaSoltaTpEmb} | ${item.VeicMarca} | ${item.contraMarca}`
      cor = 'table-info';
      break;
    case '3':
      /* carga solta */
      descricao = `${item.tipo.toUpperCase()} ${item.grtp} | ${descricaoCE}`;
      break;
    case '4':
      descricao = `${item.tipo.toUpperCase()} | ${item.VeicChassi} | ${item.VeicMarca} | ${item.contraMarca}`;
      break;
    default:
      descricao = item.codtp
      break;
  }
  return { ...item, descricao, ncm, ncmdescr, cor, }
}