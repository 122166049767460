import {
  Button, Form, Modal, InputGroup,
} from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import config from '../Components/config/config';

import { placa } from '../Components/formatos';

export default function CadVeic({ veiculo = '', variant = 'secondary' }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Authorization = config.Key;

  const [Values, setValues] = useState({ id: '', Placa: '', tipo: '' });
  const [Tipos, setTipos] = useState([]);
  async function BuscarTipos() {
    const reqs = await fetch(`${config.urlRootNode}Veiculos/Listar_tipo_veiculo`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
    });
    const ress = await reqs.json();

    if (reqs.status === 200) {
      setTipos(ress);
    } else {
      setTipos([]);
    }
  }

  useEffect(() => {
    if (show === true) {
      BuscarTipos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  async function CadastrarVeiculo(val) {
    const reqs = await fetch(`${config.urlRootNode}Veiculos/Veiculo_cad`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization,
      },
      body: JSON.stringify(val),
    });
    if (reqs.status === 200) {
      const ress = await reqs.json();
      veiculo(ress.placa);
      handleClose();
    }
  }
  return (
    <>
      <Button variant={variant} onClick={handleShow}>
        Cadastrar
      </Button>
      <Modal backdrop="static" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cadastro de Veiculos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text>Placa</InputGroup.Text>
            <Form.Control value={placa(Values.Placa)} maxLength="7" onChange={(e) => setValues((prevState) => ({ ...prevState, Placa: e.target.value }))} aria-label="Small" />
          </InputGroup>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Text>Tipo</InputGroup.Text>
            <Form.Select value={Values.tipo} size="sm" onChange={(a) => { setValues((prevState) => ({ ...prevState, tipo: a.target.value })); }}>
              <option>Selecione</option>
              {typeof Tipos !== 'undefined' && Tipos.map((item) => (
                <option key={item.id} value={item.id}>{item.tipo}</option>
              ))}
            </Form.Select>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary" onClick={() => CadastrarVeiculo(Values)}>
            Cadastrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
