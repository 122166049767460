import React from 'react';
import { Button, ButtonGroup, Card } from 'react-bootstrap';
import { useNavigate, useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();
  // console.log(error);
  const navigate = useNavigate();
  return (
    <div className="error-page">
      <Card className="bg-dark text-white" style={{ width: '40rem' }}>
        <Card.Img variant="top" />
        <Card.ImgOverlay>
          <Card.Title>Aparentemente algo deu errado</Card.Title>
          <Card.Body style={{
            minHeight: '180px',
            maxHeight: '180px',
            overflowY: 'auto',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            borderRadius: '20px',
          }}
          >
            <ul>
              <li>{error.statusText || error.message}</li>
              <li>{error.data}</li>
            </ul>
            <ButtonGroup style={{ position: 'absolute', bottom: 15, right: 45 }} className="botao_canto">
              <Button variant="warning" size="sm" onClick={() => { navigate(-1); }}>Pagina Anterior</Button>
              <Button variant="danger" size="sm" onClick={() => { navigate('/'); }}>Ir para Inicio</Button>
            </ButtonGroup>
          </Card.Body>
        </Card.ImgOverlay>
      </Card>
    </div>
  );
}
