import React from 'react';
import {
  Page, Text, View, Document, StyleSheet,
} from '@react-pdf/renderer';
import { docto, formatarValorQuilograma } from '../../../../Components/formatos';
import { dataEdi } from '../separador';
// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingHorizontal: '5mm',
    width: '100%'
  },
  text: {
    fontSize: 9,
  },
  cab: {
    backgroundColor: 'lightgrey',
    fontSize: 10,
    borderBottom: 1,
    marginBottom: 10,
    marginTop: 5
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 5,
    marginTop: 5
  },
  tableRow: {
    flexDirection: "row"
  },
  tableCol0: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol1: {
    width: "70%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol2: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol3: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    margin: "auto",
    marginTop: 3,
    fontSize: 8
  }

});

// Create Document Component
export default function EscalaPdf({ escala }) {
  return (
    <Document>
      <Page key="as" orientation="portrait" size="A4" style={styles.page}>
        {escala !== undefined && escala.map((escala) => {
          return (
            <>
              <View key={`cabeçalho`}>
                <Text style={styles.cab}>MV: {escala.navio} V.{escala.viagem} | IMO: {escala.imo} | Escala: {escala.numero} | ATR: {dataEdi(escala.previsao)} | {formatarValorQuilograma(escala.peso)}</Text>
              </View>
              {escala.manifestos.map((manifesto) => (<>
                <View>{`cabeçalho2`}
                  <Text style={styles.cab}>Manifesto {manifesto.manifesto} | {formatarValorQuilograma(manifesto.peso)}</Text>
                  <Text style={styles.text}>Agencia: {docto(manifesto.AgenciaDoc)} - {manifesto.AgenciaNome}</Text>
                  <Text style={styles.text}>Armador: {manifesto.ArmadorNome}</Text>
                  <Text style={styles.text}>Embarque: {manifesto.portoeCod} - {manifesto.PortoeNome}</Text>
                  <Text style={styles.text}>Desembarque: {manifesto.portodCod} - {manifesto.PortodNome}</Text>
                  <Text style={styles.text}>{manifesto.TrafegoCod} - {manifesto.TrafegoNome} </Text>
                </View>
                {manifesto.cemercantes.map((ce, ceind) => (<View key={ceind}>
                  <Text style={styles.cab}>CeMercante {ce.CeNumero} | {formatarValorQuilograma(ce.peso)}</Text>
                  <Text style={styles.text}>{ce.tipoConhec}: {ce.BLOrig}</Text>
                  <Text style={styles.text}>Categoria: {ce.CatCarga}</Text>
                  <Text style={styles.text}>Situação: {ce.SituCarga}</Text>
                  <Text style={styles.text}>Data Situação: {dataEdi(ce.dataSit)}</Text>
                  <Text style={styles.text}>OrigemCod: {ce.OrigemCod} - {ce.PortoEmbarque}</Text>
                  <Text style={styles.text}>DestinoCod: {ce.DestinoCod} - {ce.Desembarque}</Text>
                  <Text style={styles.text}>Beneficiario: {ce.ConsgDoc} - {ce.ConsgNome}</Text>
                  <Text style={styles.text}>Descrição: {ce.descricao}</Text>
                  <View style={styles.table}>
                    {/* Cabeçalho da tabela */}
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol0}>
                        <Text style={styles.tableCell}>Item</Text>
                      </View>
                      <View style={styles.tableCol1}>
                        <Text style={styles.tableCell}>Descrição</Text>
                      </View>
                      <View style={styles.tableCol2}>
                        <Text style={styles.tableCell}>Ncm</Text>
                      </View>
                      <View style={styles.tableCol3}>
                        <Text style={styles.tableCell}>Peso</Text>
                      </View>
                    </View>
                    {/* Linhas da tabela */}
                    {ce.items.map((item, index) => {
                      let descricao = 'asd';
                      let ncm = item.ncms[0] && item.ncms[0].codmerc ? `${item.ncms[0].codmerc}` : '';
                      // let ncmdescr = item.ncms[0] && item.ncms[0].codmerc ? `${item.ncms[0].codmerc} - ${item.ncms[0].descr}` : '';
                      switch (item.codtp) {
                        case '1':
                          descricao = ''
                          break;
                        case '2':
                          /* carga solta */
                          descricao = `${item.tipo} | ${parseFloat(item.cargaSoltaqtd > 0 ? item.cargaSoltaqtd : '0')} ${item.cargaSoltaTpEmb} | ${item.VeicMarca} | ${item.contraMarca}`
                          break;
                        case '3':
                          /* carga solta */
                          descricao = `${item.tipo.toUpperCase()} ${item.grtp} | ${ce.descricao}`;
                          break;
                        case '4':
                          descricao = `${item.tipo} | ${item.VeicChassi} | ${item.VeicMarca} | ${item.contraMarca}`;
                          break;
                        default:
                          descricao = item.codtp
                          break;
                      }
                      return (
                        <View style={styles.tableRow} key={`it${index}`}>
                          <View style={styles.tableCol0}>
                            <Text style={styles.tableCell}>{parseInt(item.item)}</Text>
                          </View>
                          <View style={styles.tableCol1}>
                            <Text style={styles.tableCell}>{descricao}</Text>
                          </View>
                          <View style={styles.tableCol2}>
                            <Text style={styles.tableCell}>{ncm}</Text>
                          </View>
                          <View style={styles.tableCol3}>
                            <Text style={styles.tableCell}>{formatarValorQuilograma(parseFloat(item.Pbruto))}</Text>
                          </View>
                        </View>
                      )
                    })}

                  </View>
                </View>))}
              </>))}
            </>
          )
        })}
      </Page>
    </Document>
  );
}
